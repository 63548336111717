import React, { useCallback, useMemo, useRef, useState, useEffect } from "react";

import {
  assocPath,
  clamp,
  concat,
  dropWhile,
  filter,
  find,
  groupBy,
  includes,
  indexBy,
  join,
  lensPath,
  lensProp,
  map,
  mergeLeft,
  none,
  over,
  path,
  pathOr,
  pick,
  prop,
  propEq,
  propOr,
  replace,
  set,
  sum,
  take,
  tap,
  times,
  toPairs,
  values,
  view,
} from "ramda";

import dayjs from "dayjs";

import debounce from "lodash.debounce";

import { Map } from "google-maps-react";

import { useLocalStorage } from "../../lib/use-local-storage";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { cpf } from "cpf-cnpj-validator";
import MaskedInput from "react-text-mask";

import big from "bigjs-literal/macro";

import { ChakraProvider, extendTheme } from "@chakra-ui/core";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  MenuItem,
  NumberInput,
  NumberInputField,
  Progress,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Stack,
  Tag,
  Text,
  Tooltip,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/core";

import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/core";

import { CalendarIcon, ChevronDownIcon, SmallCloseIcon } from "@chakra-ui/icons";
import { FaChevronLeft, FaChevronRight, FaHome, FaMapMarkerAlt, FaMinus, FaPlus, FaQuestionCircle, FaSearch } from "react-icons/fa";
import { HiOutlineShoppingCart } from "react-icons/hi";

import { List, ListItem } from "@chakra-ui/core";

import {
  placeAddress,
  placeCoordinates,
  predictionMainText,
  predictionPlaceId,
  predictionSecondaryText,
  useGooglePlacesAutocomplete,
} from "../../lib/google-maps";

import { FullScreenDialog, useDialogState, bindDialog } from "./dialog";

import {
  Link as RouterLink,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";

import { gql, useApolloClient, useQuery, useMutation } from "@apollo/client";

import { usePagarMe } from "../../lib/pagarme";

import copyToClipboard from "copy-to-clipboard";

import {
  BaseRoute,
  routeTemplate,
  usePaths,
  useChainHandle,
} from "../Path";

import poweredByGoogle from "./powered_by_google_on_white.png";
import mapIcon from "./map.png";

export const formatNumber = (number, decimals = 2, thousands = ".", decimal = ",") => {
  const [a, b] = Number(number).toFixed(decimals).split(".");
  return [
    a.replace(/\B(?=(\d{3})+(?!\d))/g, thousands),
    b,
  ].join(decimal);
}

const useScheduleForm = () => {
  const chain = useChainHandle();

  const [schedule, setSchedule] = useLocalStorage(`cart${chain}`, {});

  const { data: productsResult, loading: loadingProducts } = useProducts({
    scheduleDate: schedule.date,
    service: schedule.service,
    clinicId: schedule.clinic?.id,
  });

  const availableProducts = productsResult
    |> pathOr([], ["clinic", "products"])
    |> filter(prop("available"))
    |> indexBy(prop("id"));

  const selectedProducts = (schedule.products || {})
    |> toPairs
    |> filter(([id]) => id in availableProducts)
    |> map(([id, props]) => ({ ...availableProducts[id], ...props }))
    |> indexBy(prop("id"));

  const productsPatients = (schedule.productsPatients || {})
    |> toPairs
    |> filter(([id]) => id in selectedProducts)
    |> map(([id, patients = []]) => [
      id,
      (patients |> take(selectedProducts[id] |> propOr(0, "quantity"))),
    ]);

  const total = values(selectedProducts)
    .reduce((total, { price, quantity }) => big`${total} + ${price} * ${quantity}`, 0);

  const totalQuantity = values(selectedProducts).map(prop("quantity")) |> sum;

  const patientsWithProducts = (schedule.patients || [])
    .map(patient => {
      const products = productsPatients
        |> filter(([_, ids]) => includes(patient.id, ids))
        |> map(([productId]) => availableProducts[productId])
        |> filter(Boolean);

      return { ...patient, products };
    })
    .filter(({ products }) => products.length > 0);

  return {
    schedule,
    setSchedule,
    total,
    totalQuantity,
    patientsWithProducts,
    loadingProducts,
    resetCart: () => setSchedule(pick(["name", "email", "phoneNumber", "cpf", "address", "patients"])),
  };
}

const ScheduleFormContext = React.createContext();
const useCurrentScheduleForm = () => React.useContext(ScheduleFormContext);

const ServiceBox = ({ title, description, icon, ...props }) => (
  <Box
    role="group"
    p={5}
    display="flex"
    shadow="md"
    rounded="md"
    cursor="pointer"
    boxShadow="0px 2px 12px 2px rgba(0, 0, 0, 0.15)"
    _hover={{ bg: "blue.500" }}
    {...props}
  >
    <Box mr={3} flexShrink="0" as={icon} _groupHover={{ color: "white" }} h="32px" w="32px" color="blue.400" />
    <Box>
      <Box as={Heading} fontSize="xl" color="gray.900" _groupHover={{ color: "white" }}>{title}</Box>
      <Box as={Text} mt={2} color="gray.500" _groupHover={{ color: "white" }}>{description}</Box>
    </Box>
    <Box ml={3} flexShrink="0" as={FaChevronRight} _groupHover={{ color: "white" }} w="32px" h="32px" color="gray.600" />
  </Box>
);

const addressFragment = gql`
  fragment AddressFragment on Address {
    street
    number
    district
    complement
    city
    state
    zipCode
    geoLocation {
      lat
      lng
    }
  }
`;

const installmentsFragment = gql`
  fragment InstallmentFragment on PaymentInstallment {
    count
    interestRate
    amount
  }
`;

const paymentFragment = gql`
  fragment PaymentFragment on Payment {
    __typename
    type: __typename
    canPay
    ...on PendingPayment {
      installments {
        ...InstallmentFragment
      }
    }
    ...on RefusedPayment {
      message
      installments {
        ...InstallmentFragment
      }
    }
    ...on ChargedBackPayment {
      date
    }
    ...on RefundedPayment {
      date
      installments {
        ...InstallmentFragment
      }
    }
    ...on SuccessfullPayment {
      date
      installment {
        count
        interestRate
      }
    }
  }
  ${installmentsFragment}
`;

const appointmentFragment = gql`
  fragment AppointmentFragment on PublicAppointment {
    id
    date
    time
    status
    clinic {
      address {
        ...AddressFragment
      }
    }
    client {
      name
    }
    address {
      ...AddressFragment
    }
    fees
    total
    services {
      price
      service
      patient {
        id
        name
        birthDate
      }
      product {
        id
        name
      }
    }
    payment {
      ...PaymentFragment
    }
  }
  ${addressFragment}
  ${paymentFragment}
`;

const appointmentQuery = gql`
  query AppointmentQuery($id: String!) {
    appointment(id: $id) {
      ...AppointmentFragment
    }
  }
  ${appointmentFragment}
`;

const chainQuery = gql`
  query ChainServicesQuery($handle: String!) {
    chain(handle: $handle) {
      name
      logo {
        url
      }
      services
    }
  }
`;

const Landing = ({ onChange }) => {
  const chainHandle = useChainHandle();

  const { data, loading } = useQuery(chainQuery, {
    variables: {
      handle: chainHandle,
    },
  });

  if (loading || !data) return null;

  const { name = "", services, logo: { url: logoUrl = "" } = {} } = data.chain;

  return (
    <Stack spacing={6} alignItems="center">
      <Box as="header" textAlign="center" mt={6}>
        {logoUrl !== "" &&
          <Box
            as="img"
            src={logoUrl}
            alt={name}
            width="450px"
            maxWidth="90vw"
            height="140px"
            style={{ objectFit: "contain" }}
          />
        }
      </Box>

      <Text color="gray.900" fontSize="xl">O que gostaria de agendar?</Text>

      <Stack spacing={6} px={6} pb={6}>
        {services.includes("HOME_VACCINATION") && (
          <ServiceBox
            title="Vacinação Domiciliar"
            description="Seja vacinado com toda a segurança e conforto da sua residência."
            icon={FaHome}
            onClick={() => onChange("HOME_VACCINATION")}
          />
        )}

        {services.includes("CLINIC_VACCINATION") && (
          <ServiceBox
            title="Vacinação na Clínica"
            description="Evite esperas e marque um horário para ser vacinado na clínica."
            icon={FaMapMarkerAlt}
            onClick={() => onChange("CLINIC_VACCINATION")}
          />
        )}
      </Stack>
    </Stack>
  );
}

const clinicsQuery = gql`
  query ChainServicesQuery($chain: String!, $location: GeoCoordinates!, $service: AppointmentService!) {
    chain(handle: $chain) {
      clinics {
        id
        distance(geoCoordinates: $location)
        attendsLocation(geoCoordinates: $location)
        servicePrice(service: $service, geoCoordinates: $location)
        address {
          street
          number
          complement
          district
          city
          state
          zipCode
          geoLocation {
            lat
            lng
          }
        }
      }
    }
  }
`;

const ClinicSelect = ({ onChange }) => {
  const { schedule = {} } = useCurrentScheduleForm();
  const chain = useChainHandle();

  const { data, loading } = useQuery(clinicsQuery, {
    variables: {
      chain,
      location: (schedule.address || {}) |> pick(["lat", "lng"]),
      service: schedule.service,
    },
    skip: !schedule.service || !schedule.address,
  });

  if (loading) return null;

  const clinics = data |> pathOr([], ["chain", "clinics"]);

  const formatDistance = distance =>
    distance < 1000
      ? `${distance.toFixed(0).replace(".", ",")} metros`
      : `${(distance / 1000).toFixed(1).replace(".0", "").replace(".", ",")} km`;

  return (
    <Stack>
      {schedule.service === "HOME_VACCINATION" && <Text p={8}>Selecione por qual unidade prefere ser atendido:</Text>}
      {schedule.service === "CLINIC_VACCINATION" && <Text p={8}>Selecione qual clínica prefere ser atendido:</Text>}

      {clinics.map(clinic => (
        <React.Fragment key={clinic.id}>
          <Flex px={6} py={2} onClick={() => onChange(clinic)} cursor="pointer">
            <Box as={FaMapMarkerAlt} w="24px" h="24px" mx={6} my={3} ml={0} />
            <Stack spacing={0}>
              <Text color="gray.700">{joinNonNull([clinic.address.street, clinic.address.number], ", ")}</Text>
              <Text color="gray.700">{joinNonNull([clinic.address.city, clinic.address.state], " - ")}</Text>
              {schedule.service === "CLINIC_VACCINATION" && <Text color="gray.400">A {formatDistance(clinic.distance)} de distância.</Text>}
              {clinic.servicePrice > 0 && <Text color="gray.600">Taxa domiciliar <b>R$ {formatNumber(clinic.servicePrice)}</b></Text>}
            </Stack>
          </Flex>
          <Divider />
        </React.Fragment>
      ))}
    </Stack>
  );
}

const TopBar = ({ left, right, children, ...props }) => (
  <Box mb="50px">
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={4}
      boxShadow="0px 1px 16px 1px rgba(0, 0, 0, 0.1)"
      bg="blue.400"
      color="white"
      position="fixed"
      left="0"
      right="0"
      top="0"
      height="50px"
      zIndex="sticky"
      {...props}
    >
      <Box width="36px" display="flex" justifyContent="flex-start">{left}</Box>
      <Box flex="1" display="flex" justifyContent="center" fontSize="16px" fontWeight="medium">{children}</Box>
      <Box width="36px" display="flex" justifyContent="flex-end">{right}</Box>
    </Box>
  </Box>
);

const BottomBar = ({ children }) => (
  <Box mb="50px" position="relative">
    {children}
  </Box>
);

const BottomButton = props => (
  <BottomBar>
    <Button
      width="100vw"
      textAlign="center"
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={4}
      bg="blue.400"
      color="white"
      borderRadius="0"
      position="fixed"
      left="0"
      right="0"
      bottom="0"
      height="50px"
      zIndex="sticky"
      _hover={{ bg: "blue.400" }}
      {...props}
    />
  </BottomBar>
);

const joinNonNull = (list, separator) => list.filter(Boolean).join(separator);

const validateZipCode = zipCode => zipCode.replace(/\D/g, "").length === 8;

const zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/];
const ZipCodeInput = React.forwardRef((props, ref) => (
  <MaskedInput
    mask={zipCodeMask}
    ref={ref}
    showMask={false}
    {...props}
    render={(ref, props) => (
      <Input ref={ref} {...props} />
    )}
  />
));
ZipCodeInput.displayName = "ZipCodeInput";

const AddressSearch = ({ value, onChange, children }) => {
  const [input, setInput] = useState("");
  const inputRef = useRef();

  const [address, setAddress] = useState(value);
  const addressCompletionDialog = useDialogState({ initialOpen: address != null });

  const {
    predictions,
    search,
    searching,
    getDetails,
    clearPredictions,
    registerAttributionRef,
  } = useGooglePlacesAutocomplete();

  const debouncedSearch = useMemo(() => debounce(search, 400), [search]);

  const onSearch = useCallback(event => {
    const input = event.target.value;
    setInput(input);
    if (input.trim().length > 3) debouncedSearch({
      maps: window.google.maps,
      input,
      types: ["address"],
      componentRestrictions: { country: "br" },
    });
  }, [debouncedSearch]);

  const onClearInput = useCallback(() => {
    setInput("");
    clearPredictions();
    if (inputRef.current) inputRef.current.focus();
  }, [inputRef, clearPredictions]);

  const [requiresNumber, setRequiresNumber] = useState(true);
  const { control, clearErrors, errors, handleSubmit, register, reset, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      number: address?.number || "",
      complement: address?.complement || "",
      district: address?.district || "",
      zipCode: address?.zipCode || "",
    },
    shouldFocusError: true,
  });

  const validateNumber = useCallback(number => requiresNumber ? number.length > 0 : true, [requiresNumber]);
  const onSubmit = customAddressComponents => onChange({ ...address, ...customAddressComponents });

  const onSelectPrediction = useCallback(prediction => {
    getDetails({ placeId: predictionPlaceId(prediction), fields: ["address_components", "geometry"] })
      .then(place => ({ ...placeAddress(place), ...placeCoordinates(place) }))
      .then(tap(setAddress))
      .then(tap(({ number, district, zipCode = "" }) => setTimeout(() => reset({ number, district, zipCode }), 100)))
      .then(addressCompletionDialog.open);
  }, [getDetails, addressCompletionDialog, reset]);

  return (
    <>
      <Stack spacing={2}>
        <Stack p={6} spacing={1}>
          <InputGroup>
            <InputLeftElement><Icon as={FaSearch} color="gray.700" /></InputLeftElement>
            <Input placeholder="Busque seu endereço" onChange={onSearch} value={input} ref={inputRef} />
            <InputRightElement>
              {searching && (
                <Flex>
                  <Spinner color="blue.500" />
                </Flex>
              )}
              {!searching && input.length > 0 && <Icon as={SmallCloseIcon} color="blue.500" onClick={onClearInput} />}
            </InputRightElement>
          </InputGroup>
          <Flex justifyContent="flex-end">
            <img id="poweredByGoogle" alt="Powered by Google" src={poweredByGoogle} ref={registerAttributionRef} />
          </Flex>
        </Stack>

        <Stack>
          {input.trim().length > 0 && predictions.map((prediction, i) => (
            <React.Fragment key={i}>
              <Flex px={6} py={2} onClick={() => onSelectPrediction(prediction)} cursor="pointer">
                <Box as={FaMapMarkerAlt} w="24px" h="24px" mx={6} my={3} ml={0} />
                <Stack spacing={0}>
                  <Text fontSize="lg">{predictionMainText(prediction)}</Text>
                  <Text color="gray.500">{predictionSecondaryText(prediction)}</Text>
                </Stack>
              </Flex>
              <Divider />
            </React.Fragment>
          ))}
        </Stack>
      </Stack>

      <FullScreenDialog {...bindDialog(addressCompletionDialog)}>
        <TopBar left={<Box as={FaChevronLeft} onClick={addressCompletionDialog.close} />}>
          Confirme o Endereço
        </TopBar>

        <Stack>
          <Box height="40vh" position="relative">
            <Map
              initialCenter={address}
              center={address}
              google={window.google}
              draggable={false}
              scrollwheel={false}
              disableDoubleClickZoom={false}
              zoom={17}
              zoomControl={false}
              mapTypeControl={false}
              scaleControl={false}
              streetViewControl={false}
              panControl={false}
              rotateControl={false}
              fullscreenControl={false}
            />
          </Box>

          {address && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box p={4}>
                <Flex justifyContent="space-between" alignItems="flex-start">
                  <Text>{address.street}</Text>
                  <Button variant="link" color="blue.600" mt="2px" onClick={addressCompletionDialog.close}>Editar</Button>
                </Flex>
                <Text color="gray.500">{address.zipCode && validateZipCode(address.zipCode) ? joinNonNull([address.district, address.zipCode], ", ") : address.district || ""}</Text>
                <Text color="gray.500">{joinNonNull([address.city, address.state], " - ")}</Text>
              </Box>

              <Stack p={4}>
                <Stack direction="row">
                  <FormControl isInvalid={errors.number}>
                    <FormLabel>Número</FormLabel>
                    <Input
                      isDisabled={!requiresNumber}
                      name="number"
                      defaultValue={address.number}
                      ref={register({ validate: validateNumber })}
                    />
                    <FormHelperText as="div">
                      <Checkbox
                        size="sm"
                        onChange={event => {
                          const checked = event.target.checked;
                          setRequiresNumber(!checked);
                          if (checked) {
                            setValue("number", "", { shouldValidate: false });
                            clearErrors("number");
                          }
                        }}
                      >
                        Sem número
                      </Checkbox>
                    </FormHelperText>
                    {errors.number && <FormErrorMessage>Informe o número</FormErrorMessage>}
                  </FormControl>

                  <FormControl>
                    <FormLabel>Complemento</FormLabel>
                    <Input name="complement" ref={register()} />
                  </FormControl>
                </Stack>

                <Stack direction="row">
                  <FormControl isInvalid={errors.district}>
                    <FormLabel>Bairro</FormLabel>
                    <Input name="district" ref={register({ required: true })} />
                    {errors.district && <FormErrorMessage>Informe o bairro</FormErrorMessage>}
                  </FormControl>

                  {(!address.zipCode || !validateZipCode(address.zipCode)) && (
                    <FormControl isInvalid={errors.zipCode}>
                      <FormLabel>CEP</FormLabel>
                      <Controller
                        control={control}
                        name="zipCode"
                        rules={{ validate: validateZipCode }}
                        render={({ onBlur, onChange, value }) => (
                          <ZipCodeInput name="zipCode" onChange={onChange} onBlur={onBlur} value={value} />
                        )}
                      />
                      {errors.zipCode && <FormErrorMessage>Informe o CEP</FormErrorMessage>}
                    </FormControl>
                  )}
                </Stack>
              </Stack>

              {children}
            </form>
          )}
        </Stack>
      </FullScreenDialog>
    </>
  );
}

const Product = ({ id, items, name, manufacturer, price, available, maxQuantity }) => {
  const { schedule, setSchedule } = useCurrentScheduleForm();

  const productLens = useMemo(() => lensPath(["products", id, "quantity"]), [id]);

  const clampQuantity = useMemo(() => clamp(0, maxQuantity), [maxQuantity]);
  const quantity = view(productLens, schedule) || 0;

  const onChange = useCallback(
    value => setSchedule(set(productLens, clampQuantity(parseInt(value, 10)))),
    [productLens, setSchedule, clampQuantity]
  );
  const onInc = useCallback(
    () => setSchedule(over(productLens, (quantity = 0) => clampQuantity(quantity + 1))),
    [productLens, setSchedule, clampQuantity]
  );
  const onDec = useCallback(
    () => setSchedule(over(productLens, (quantity = 0) => clampQuantity(quantity - 1))),
    [productLens, setSchedule, clampQuantity]
  );

  const itemsWithQuantity = (items || [])
    |> groupBy(path(["product", "id"]))
    |> values
    |> map(items => ({ ...items[0], quantity: items.length }));

  return (
    <Box borderBottomWidth="1px" borderBottomColor="gray.200">
      <Stack direction="row" p={4} alignItems="center" opacity={available ? "100%" : "50%"}>
        <Stack flex="1" spacing={1}>
          <Text fontSize="md">{name} {items && <Tag colorScheme="blue">Pacote</Tag>}</Text>
          <Text fontSize="sm" color="gray.400">{manufacturer}</Text>
          {itemsWithQuantity.map(item => (
            <Text key={item.product.id} style={{ marginBottom: "6px" }} fontSize="sm" color="gray.600">
              {item.product.name}
              {item.quantity > 1 &&
                <Tag fontSize="sm" color="gray.600"> {item.quantity} doses</Tag>}
            </Text>
          ))}
          <Text fontSize="lg" color="blue.500" fontWeight="bold" whiteSpace="nowrap">R$ {formatNumber(price)}</Text>
        </Stack>

        <Box width="120px" textAlign="center">
          <InputGroup>
            <InputLeftElement onClick={onDec}><Icon as={FaMinus} color={quantity > 0 ? "blue.500" : "gray.300"} /></InputLeftElement>
            <NumberInput onChange={onChange} value={quantity} min={0} max={9} isDisabled={!available} opacity="100%">
              <NumberInputField
                borderColor={quantity > 0 ? "blue.500" : "gray.300"}
                borderWidth={quantity > 0 ? "2px" : "1px"}
                color={quantity > 0 ? "blue.500" : "gray.300"}
                fontWeight="bold"
                type="number"
                textAlign="center"
                px="2.5em"
                fontSize="lg"
                opacity="100% !important"
                onClick={e => e.target.select()}
              />
            </NumberInput>
            <InputRightElement onClick={onInc}><Icon as={FaPlus} color={quantity < maxQuantity ? "blue.500" : "gray.300"} /></InputRightElement>
          </InputGroup>

          {!available && <Text fontSize="sm" color="gray.500" mt={1}>Indisponível</Text>}
        </Box>
      </Stack>
    </Box>
  );
}

const productsQuery = gql`
query ProductsQuery($scheduleDate: Date!, $clinicId: ID!, $service: AppointmentService!) {
  clinic(id: $clinicId) {
    id
    products(service: $service) {
      id
      name
      price
      __typename
      type: __typename
      ... on VaccineProduct {
        ageGroups
        available(scheduleDate: $scheduleDate, clinicId: $clinicId)
        maxQuantity(scheduleDate: $scheduleDate, clinicId: $clinicId)
      }
      ... on BundleProduct {
        ageGroups
        available(scheduleDate: $scheduleDate, clinicId: $clinicId)
        maxQuantity(scheduleDate: $scheduleDate, clinicId: $clinicId)
        items {
          price
          product {
            id
            name
          }
        }
      }
    }
  }
}
`;

const useProducts = ({ scheduleDate, service, clinicId, ...args }) => {
  return useQuery(productsQuery, {
    variables: {
      scheduleDate,
      service,
      clinicId,
    },
    skip: !service || !clinicId,
    ...args,
  });
};

const withAgeGroup = ageGroup => ({ ageGroups = [] } = {}) => ageGroups.includes(ageGroup);

const ProductList = ({ children }) => {
  const { schedule: { service, date, clinic: { id: clinicId } = {} } = {} } = useCurrentScheduleForm();

  const { data } = useProducts({ scheduleDate: date, service, clinicId });

  const products = data |> pathOr([], ["clinic", "products"]);

  return (
    <>
      <Tabs size="sm" colorScheme="blue">
        <TabList overflow="auto" paddingBottom="2px" paddingTop="10px" position="fixed" top="50px" bg="white" zIndex="dropdown" width="100%">
          {products.filter(withAgeGroup("CHILD")).length > 0 && (
            <Tab>
              <Stack spacing="1px" minWidth="80px">
                <Text fontSize="sm" fontWeight="medium">Crianças</Text>
                <Text fontSize="xs">0 a 10 anos</Text>
              </Stack>
            </Tab>
          )}

          {products.filter(withAgeGroup("TEEN")).length > 0 && (
            <Tab>
              <Stack spacing="1px" minWidth="80px">
                <Text fontSize="sm" fontWeight="medium">Adolescentes</Text>
                <Text fontSize="xs">10 a 18 anos</Text>
              </Stack>
            </Tab>
          )}

          {products.filter(withAgeGroup("ADULT")).length > 0 && (
            <Tab>
              <Stack spacing="1px" minWidth="80px">
                <Text fontSize="sm" fontWeight="medium">Adultos</Text>
                <Text fontSize="xs">18 a 59 anos</Text>
              </Stack>
            </Tab>
          )}

          {products.filter(withAgeGroup("ELDER")).length > 0 && (
            <Tab>
              <Stack spacing="1px" minWidth="80px">
                <Text fontSize="sm" fontWeight="medium">Idosos</Text>
                <Text fontSize="xs">60+ anos</Text>
              </Stack>
            </Tab>
          )}
        </TabList>

        <TabPanels paddingTop="60px">
          <TabPanel p="0">
            <Stack background="#f7fafc">
              {products.filter(product => withAgeGroup("CHILD")(product) && product.type === "BundleProduct").map(product => <Product key={product.id} {...product} />)}
            </Stack>
            <Stack>
              {products.filter(product => withAgeGroup("CHILD")(product) && product.type === "VaccineProduct").map(product => <Product key={product.id} {...product} />)}
            </Stack>
          </TabPanel>

          <TabPanel p="0">
            <Stack background="#f7fafc">
              {products.filter(product => withAgeGroup("TEEN")(product) && product.type === "BundleProduct").map(product => <Product key={product.id} {...product} />)}
            </Stack>
            <Stack>
              {products.filter(product => withAgeGroup("TEEN")(product) && product.type === "VaccineProduct").map(product => <Product key={product.id} {...product} />)}
            </Stack>
          </TabPanel>

          <TabPanel p="0">
            <Stack background="#f7fafc">
              {products.filter(product => withAgeGroup("ADULT")(product) && product.type === "BundleProduct").map(product => <Product key={product.id} {...product} />)}
            </Stack>
            <Stack>
              {products.filter(product => withAgeGroup("ADULT")(product) && product.type === "VaccineProduct").map(product => <Product key={product.id} {...product} />)}
            </Stack>
          </TabPanel>

          <TabPanel p="0">
            <Stack>
              {products.filter(withAgeGroup("ELDER")).map(product => <Product key={product.id} {...product} />)}
            </Stack>
          </TabPanel>
        </TabPanels>
      </Tabs>

      {children}
    </>
  );
}

const PatientDefinition = ({ product }) => {
  const { schedule: { patients = [], productsPatients = {} }, setSchedule } = useCurrentScheduleForm();

  const newPatientDialog = useDialogState();

  const options = useMemo(() => {
    const selection = (productsPatients |> propOr([], product.id)) |> take(product.quantity);
    return patients
      .map(patient => ({
        ...patient,
        selected: selection.indexOf(patient.id) === product.sequence,
        disabled: patient.selected || (selection |> includes(patient.id)),
      }));
  }, [product, productsPatients, patients]);

  const selected = (options.find(prop("selected")) || {}).name;

  const onSelectPatient = useCallback(patientId => {
    const current = [...propOr([], product.id, productsPatients)];

    const index = current.findIndex(id => id === patientId);
    if (index) delete current[index];

    current[product.sequence] = patientId;
    setSchedule(assocPath(["productsPatients", product.id], current));
  }, [product, productsPatients, setSchedule]);

  const onNewPatient = useCallback(values => {
    const patient = {
      ...values,
      id: parseInt(Math.random() * 100000, 10),
    };
    setSchedule(over(lensProp("patients"), (patients = []) => [...patients, patient]));
    onSelectPatient(patient.id);
    newPatientDialog.close();
  }, [setSchedule, newPatientDialog, onSelectPatient]);

  const newPatientInitialValues = useMemo(() => (patients[0] || {}) |> pick(["motherName"]), [patients]);

  return (
    <Box borderBottomWidth="1px" borderBottomColor="gray.200">
      <Stack p={4}>
        <Stack flex="1" spacing={1}>
          <Text fontSize="md">{product.name}</Text>
          <Text fontSize="sm" color="gray.400">{product.manufacturer}</Text>
        </Stack>

        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />} justifyContent="space-between" display="flex" textAlign="left" borderWidth="1px" borderColor="gray.300" rounded="4px" bg="white">
            {selected || "Quem será vacinado?"}
          </MenuButton>

          <MenuList maxH="50vh" overflowY="auto">
            {options.length > 0 && (
              <>
                {options.map(({ id, name, disabled }) => (
                  <MenuItem key={id} value={id} isDisabled={disabled} onClick={() => onSelectPatient(id)}>{name}</MenuItem>
                ))}
                <MenuDivider />
              </>
            )}
            <MenuItem onClick={newPatientDialog.open}>Cadastrar Paciente</MenuItem>
          </MenuList>
        </Menu>
      </Stack>

      <FullScreenDialog {...bindDialog(newPatientDialog)}>
        <TopBar left={<Box as={FaChevronLeft} onClick={newPatientDialog.close} />}>Dados do Paciente</TopBar>
        <PatientForm onSubmit={onNewPatient} initialValues={newPatientInitialValues}>
          <BottomButton type="submit">Salvar</BottomButton>
        </PatientForm>
      </FullScreenDialog>
    </Box>
  );
}

const patientFormSchema = yup.object().shape({
  name: yup.string()
    .required("Informe o nome")
    .test("full-name", "O nome precisa ser completo", value => value.trim().includes(" "))
    .test("without-dot", "O nome não pode ser abreviado", value => !value.includes(".")),

  motherName: yup.string()
    .required("Informe o nome")
    .test("full-mother-name", "O nome precisa ser completo", value => value.trim().includes(" ")),

  sex: yup.string()
    .oneOf(["MALE", "FEMALE"], "Informe o sexo")
    .required("Informe o sexo"),

  birthDate: yup.date()
    .typeError("Data inválida")
    .required("Informe a data de nascimento"),

  cpf: yup.string()
    .test("validate-cpf", "CPF inválido", value => value === "" || cpf.isValid(value)),
});

const PatientForm = ({ initialValues, onSubmit, children }) => {
  const { handleSubmit, control, errors, register } = useForm({
    defaultValues: {
      name: "",
      sex: "",
      motherName: "",
      birthDate: "",
      cpf: "",
      ...initialValues,
    },
    resolver: yupResolver(patientFormSchema),
  });

  const onFormSubmit = values =>
    onSubmit({ ...values, birthDate: dayjs(values.birthDate).format("YYYY-MM-DD") });

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Stack spacing={4} p={6}>
        <FormControl isInvalid={errors.name}>
          <FormLabel>Nome</FormLabel>
          <Input name="name" ref={register()} />
          {errors.name && <FormErrorMessage>{errors.name?.message}</FormErrorMessage>}
          <FormHelperText>Nome completo sem abreviações</FormHelperText>
        </FormControl>

        <FormControl isInvalid={errors.name}>
          <FormLabel>Sexo</FormLabel>
          <Controller
            control={control}
            name="sex"
            render={({ onBlur, onChange, value }) => (
              <RadioGroup name="sex" value={value} onChange={onChange} onBlur={onBlur}>
                <Stack direction="row" spacing={5}>
                  <Radio value="MALE">Masculino</Radio>
                  <Radio value="FEMALE">Feminino</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
          {errors.sex && <FormErrorMessage>{errors.sex?.message}</FormErrorMessage>}
        </FormControl>

        <FormControl isInvalid={errors.birthDate}>
          <FormLabel>Data de Nascimento</FormLabel>
          <Input name="birthDate" type="date" ref={register()} />
          {errors.birthDate && <FormErrorMessage>{errors.birthDate?.message}</FormErrorMessage>}
        </FormControl>

        <FormControl isInvalid={errors.motherName}>
          <FormLabel>Nome da Mãe</FormLabel>
          <Input name="motherName" ref={register()} />
          <FormHelperText>Requerido pelo SUS</FormHelperText>
          {errors.motherName && <FormErrorMessage>{errors.motherName?.message}</FormErrorMessage>}
        </FormControl>

        <FormControl isInvalid={errors.cpf}>
          <FormLabel>CPF do Paciente</FormLabel>
          <Controller
            control={control}
            name="cpf"
            render={({ onBlur, onChange, value }) => (
              <CPFInput name="cpf" onChange={onChange} onBlur={onBlur} value={value} />
            )}
          />
          <FormHelperText>Deixe em branco se o paciente ainda não tiver um CPF</FormHelperText>
          {errors.cpf && <FormErrorMessage>{errors.cpf?.message}</FormErrorMessage>}
        </FormControl>

        {children}
      </Stack>
    </form>
  );
}

const PatientsList = ({ children }) => {
  const { schedule: { service, date, clinic: { id: clinicId } = {}, products: selectedProducts = {} } } = useCurrentScheduleForm();

  const { data } = useProducts({ scheduleDate: date, service, clinicId });

  const products = useMemo(() => (
    data |> pathOr([], ["clinic", "products"]) |> filter(prop("available")) |> indexBy(prop("id"))
  ), [data]);

  const patientsProducts = useMemo(() => (
    toPairs(selectedProducts)
      .filter(([id, { quantity }]) => id in products && quantity && quantity > 0)
      .map(([id, { quantity }]) => ({ ...products[id], id, quantity }))
      .flatMap(product => times(i => ({ ...product, sequence: i }), product.quantity))
  ), [selectedProducts, products]);

  return (
    <>
      <Stack spacing={2}>
        {patientsProducts.map((product, i) => (
          <PatientDefinition key={i} product={product} />
        ))}
      </Stack>
      {children}
    </>
  );
}

const availableSpotsQuery = gql`
  query AvailableSpotsQuery($clinicId: ID!, $service: AppointmentService!) {
    clinic(id: $clinicId) {
      id
      appointmentSpots(service: $service) {
        date
        schedule {
          time
          available
        }
      }
    }
  }
`;

const DateTimeSelector = ({ date, time, onChange, children }) => {
  const { schedule: { service, clinic: { id: clinicId } = {} } = {} } = useCurrentScheduleForm();

  const { data } =
    useQuery(availableSpotsQuery, {
      variables: {
        service,
        clinicId,
      },
      skip: !clinicId || !service,
    });

  const availableAppointmentSpots = data
    |> pathOr([], ["clinic", "appointmentSpots"])
    |> filter(({ schedule = [] }) => schedule.length > 0 && schedule.some(prop("available")));

  const { handleSubmit, control, errors, register, watch, setValue } = useForm({
    defaultValues: {
      date,
      time,
    },
  });

  const selectedDate = watch("date");
  const selectedTime = watch("time");

  const availableTimes = availableAppointmentSpots
    |> find(propEq("date", selectedDate))
    |> propOr([], "schedule")
    |> map(({ time, available }) => {
      const [start, end] = time.split("--");
      return { value: time, start, end, available };
    });

  React.useEffect(() => {
    if (selectedDate && availableAppointmentSpots.length > 0) {
      const isSelectedDateValid = availableAppointmentSpots.find(propEq("date", selectedDate));

      if (!isSelectedDateValid) {
        setValue("date", null);
        setValue("time", null);
      } else if (selectedTime && availableTimes.length > 0) {
        const isSelectedTimeValid = availableTimes.find(({ value, available }) => value === selectedTime && available);
        !isSelectedTimeValid && setValue("time", null);
      }
    }
  }, [selectedTime, availableTimes, selectedDate, availableAppointmentSpots, setValue]);

  return (
    <form onSubmit={handleSubmit(onChange)}>
      <Stack p={6} spacing="8">
        <FormControl isInvalid={errors.date}>
          <Controller
            control={control}
            name="date"
            rules={{ required: true }}
            render={({ onChange, value }) => availableAppointmentSpots.length > 0 && (
              <DateSelector
                label={<FormLabel>Selecione uma data</FormLabel>}
                value={value}
                dates={availableAppointmentSpots.map(prop("date"))}
                onChange={onChange}
              />
            )}
          />
          {errors.date && <FormErrorMessage>Escolha uma data</FormErrorMessage>}
        </FormControl>

        {availableAppointmentSpots.length > 0 && selectedDate && (
          <FormControl isInvalid={errors.time}>
            <FormLabel>Escolha o horário</FormLabel>
            <Select placeholder="Selecione um horário" name="time" ref={register({ required: true })}>
              {availableTimes.map(({ value, start, end, available }) => (
                <option key={value} value={value} disabled={!available}>
                  {service === "HOME_VACCINATION" ? `Entre ${start}h e ${end}h` : `${start}h`}
                </option>
              ))}
            </Select>
            {service === "HOME_VACCINATION" && (
              <FormHelperText as="div" cursor="pointer" style={{ display: "none" }}>
                <Tooltip
                  placement="top"
                  hasArrow
                  label="Como a logística do atendimento domiciliar não é simples, o horário de atendimento pode variar dentro de uma faixa pré-estimada"
                >
                  <Text><Icon as={FaQuestionCircle} w="14px" h="14px" /> Porque o horário não é fixo?</Text>
                </Tooltip>
              </FormHelperText>
            )}
            {errors.time && <FormErrorMessage>Escolha um horário</FormErrorMessage>}
          </FormControl>
        )}

        {children}
      </Stack>
    </form>
  );
}

const formatScheduleTime = (time = "") => {
  const formatHour = hour => hour.toLowerCase().includes("h") ? hour.toLowerCase() : `${hour}h`;

  if (time.includes("--")) {
    const [begin, end] = time.split("--");
    return `Entre ${[formatHour(begin), formatHour(end)].join(" e ")}`;
  } else {
    return formatHour(time);
  }
}

const RadioButtonGroupContext = React.createContext();

const RadioButton = React.forwardRef(({ value, children, ...props }, ref) => {
  const { getRadioProps } = React.useContext(RadioButtonGroupContext);
  const { getInputProps, getCheckboxProps } = useRadio(getRadioProps({ value }));
  const { checked } = getInputProps();

  const inputRef = useRef();
  const inputProps = getInputProps({}, inputRef);

  return (
    <Button
      ref={ref}
      colorScheme={checked ? "blue" : "gray"}
      variant={checked ? "solid" : "outline"}
      aria-checked={checked}
      role="radio"
      onClick={() => inputRef.current.click()}
      {...getCheckboxProps()}
      {...props}
    >
      <input {...inputProps} />
      {children}
    </Button>
  );
});
RadioButton.displayName = "RadioButton";

const RadioButtonGroup = ({ name, value, defaultValue, onChange, as: Component = Box, ...props }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange,
  });

  return (
    <RadioButtonGroupContext.Provider value={{ getRadioProps }}>
      <Component {...props} {...getRootProps()} />
    </RadioButtonGroupContext.Provider>
  );
}

const scheduleBegin = range => range.split("--")[0];

const attendsHomeServiceQuery = gql`
  query AttendsHomeServiceQuery($chain: String!, $location: GeoCoordinates!) {
    chain(handle: $chain) {
      clinics {
        id
        attendsLocation(geoCoordinates: $location)
      }
    }
  }
`

const formatZipCode = zipCode => zipCode.replace(/(\d{5})(\d{3})/, "$1-$2");

const Confirmation = ({ children }) => {
  const history = useHistory();
  const graphql = useApolloClient();
  const chainHandle = useChainHandle();
  const { schedule = {}, setSchedule, patientsWithProducts, total, loadingProducts } = useCurrentScheduleForm();

  const { address = {}, clinic: { address: clinicAddress = {}, servicePrice = 0 } = {} } = schedule;

  const [editPatient, setEditPatient] = useState();

  const changeAddressDialog = useDialogState();
  const changeDateDialog = useDialogState();
  const editPatientDialog = useDialogState();
  const clinicSelectDialog = useDialogState();

  const { rootPath, cartVaccineSelectionPath } = usePaths();

  const { data: attendsHomeService = {}, loading: loadingAttendingQuery } = useQuery(attendsHomeServiceQuery, {
    variables: {
      chain: chainHandle,
      location: address |> pick(["lat", "lng"]),
    },
    skip: !address.lat || !address.lng,
  });


  const onChangeService = async service => {
    const { data } = await graphql.query({
      query: clinicsQuery,
      variables: {
        chain: chainHandle,
        service: service,
        location: schedule.address |> pick(["lat", "lng"]),
      },
      fetchPolicy: "network-only",
    });

    const clinics = data |> pathOr([], ["chain", "clinics"]);

    switch (service) {
      case "HOME_VACCINATION": {
        const attendingClinics = clinics.filter(prop("attendsLocation"));
        if (attendingClinics.length === 0) {
          alert("Infelizmente não atendemos no seu endereço.");
        } else if (attendingClinics.length === 1) {
          setSchedule(mergeLeft({ service, clinic: attendingClinics[0] }));
          changeDateDialog.open();
        } else {
          setSchedule(mergeLeft({ service }));
          clinicSelectDialog.open();
        }
        break;
      }

      case "CLINIC_VACCINATION": {
        if (clinics.length === 0) {
          alert("Desculpe o inconveniente, mas essa clínica não está atendendo no momento.");
        } else if (clinics.length === 1) {
          setSchedule(mergeLeft({ service, clinic: clinics[0] }));
          changeDateDialog.open();
        } else {
          setSchedule(mergeLeft({ service }));
          clinicSelectDialog.open();
        }
        break;
      }

      default:
        break;
    }
  }

  if (schedule.service == null) return history.replace(rootPath());

  return (
    <>
      <Box p={4}>
        <Stack>
          <FormControl>
            <FormLabel>Tipo de Atendimento:</FormLabel>
            <RadioButtonGroup as={Stack} direction="row" value={schedule.service} onChange={onChangeService}>
              <RadioButton
                flex="1"
                leftIcon={<FaHome />}
                value="HOME_VACCINATION"
                disabled={schedule.service !== "HOME_VACCINATION" && (loadingAttendingQuery || (attendsHomeService |> pathOr([], ["chain", "clinics"]) |> none(prop("attendsLocation"))))}
              >
                Domiciliar
              </RadioButton>
              <RadioButton flex="1" leftIcon={<FaMapMarkerAlt />} value="CLINIC_VACCINATION">Clínica</RadioButton>
            </RadioButtonGroup>
          </FormControl>

          {schedule.service === "HOME_VACCINATION" && (
            <>
              <Divider />

              <Stack>
                <Flex justifyContent="space-between">
                  <Text fontWeight="medium">Você será atendido em:</Text>
                  <Button variant="link" color="blue.600" onClick={changeAddressDialog.open}>Editar</Button>
                </Flex>

                <Stack direction="row" spacing="0">
                  <Box width="80px"><img src={mapIcon} alt="Endereço" /></Box>
                  <Text as="address" flex="1" fontStyle="normal">
                    {joinNonNull([address.street, address.number], ", ")}<br />
                    {joinNonNull([address.city, address.state], " - ")}<br />
                    {address.zipCode}
                  </Text>
                </Stack>
              </Stack>
            </>
          )}

          {schedule.service === "CLINIC_VACCINATION" && (
            <>
              <Divider />

              <Stack>
                <Text fontWeight="medium">Compareça no endereço da clínica:</Text>

                <Stack direction="row" spacing="0">
                  <Box width="80px"><img src={mapIcon} alt="Endereço" /></Box>
                  <Text as="address" flex="1" fontStyle="normal">
                    {joinNonNull([clinicAddress.street, clinicAddress.number], ", ")} {clinicAddress.complement}<br />
                    {joinNonNull([clinicAddress.city, clinicAddress.state], " - ")}<br />
                    {formatZipCode(clinicAddress.zipCode)}
                  </Text>
                </Stack>
              </Stack>
            </>
          )}

          <Divider />

          <Stack>
            <Flex justifyContent="space-between">
              <Text fontWeight="medium">Horário:</Text>
              <Button variant="link" color="blue.600" onClick={changeDateDialog.open}>Editar</Button>
            </Flex>
            <Flex>
              <Icon as={CalendarIcon} mr="8px" mt="4px" boxSize="20px" color="gray.600" />
              <Box>
                <Text>{dayjs(schedule.date).format("dddd, DD [de] MMMM")}</Text>
                <Text>{formatScheduleTime(schedule.service === "CLINIC_VACCINATION" ? scheduleBegin(schedule.time) : schedule.time)}</Text>
              </Box>
            </Flex>
          </Stack>
        </Stack>
      </Box>

      <Divider borderWidth="4px" borderLeftWidth="0" borderRightWidth="0" />

      {!loadingProducts && (
        <Box p={4}>
          <Stack>
            <Flex justifyContent="space-between">
              <Text fontWeight="medium">Vacinas:</Text>
              <Button variant="link" color="blue.600" onClick={() => history.push(cartVaccineSelectionPath())}>Adicionar mais vacinas</Button>
            </Flex>

            <Divider />

            {patientsWithProducts.map(({ products, ...patient }) => (
              <React.Fragment key={patient.id}>
                <Stack>
                  <Flex justifyContent="space-between" alignItems="flex-start">
                    <Box>
                      <Text fontWeight="medium">{patient.name}</Text>
                      <Text color="gray.400" fontSize="sm">{dayjs(patient.birthDate).format("DD/MM/YYYY")}</Text>
                    </Box>

                    <Button variant="link" color="blue.600" onClick={() => { setEditPatient(patient); editPatientDialog.open() }}>Editar</Button>
                  </Flex>

                  <List>
                    {products.map(product => (
                      <ListItem fontSize="sm" key={product.id}>
                        <Flex justifyContent="space-between">
                          <Text>{product.name}</Text>
                          <Text whiteSpace="nowrap">R$ {formatNumber(product.price)}</Text>
                        </Flex>
                      </ListItem>
                    ))}
                  </List>
                </Stack>
                <Divider />
              </React.Fragment>
            ))}

            {servicePrice > 0 && (
              <Flex justifyContent="space-between">
                <Text>Taxa Domiciliar</Text>
                <Text whiteSpace="nowrap">R$ {formatNumber(servicePrice)}</Text>
              </Flex>
            )}

            <Flex justifyContent="space-between">
              <Text fontSize="lg" fontWeight="bold">Total</Text>
              <Text fontSize="lg" fontWeight="bold" whiteSpace="nowrap">R$ {formatNumber(big`${total} + ${servicePrice}`)}</Text>
            </Flex>
          </Stack>
        </Box>
      )}

      {children}

      <FullScreenDialog {...bindDialog(changeAddressDialog)}>
        <TopBar left={<Box as={FaChevronLeft} onClick={changeAddressDialog.close} />}>Busque seu Endereço</TopBar>
        <AddressSearch
          value={schedule.address}
          onChange={async address => {
            const { data } = await graphql.query({
              query: clinicsQuery,
              variables: {
                chain: chainHandle,
                service: schedule.service,
                location: address |> pick(["lat", "lng"]),
              },
              fetchPolicy: "network-only",
            });

            const clinics = data |> pathOr([], ["chain", "clinics"]);

            const autoSelectClinic = clinics => {
              if (clinics.length === 0) {
                alert("Desculpe o inconveniente, mas essa clínica não está atendendo no momento.");
              } else if (clinics.length === 1) {
                setSchedule(mergeLeft({ address, clinic: clinics[0] }));
                changeAddressDialog.close();
              } else {
                setSchedule(mergeLeft({ address, clinic: clinics[0] }));
                clinicSelectDialog.open();
                changeAddressDialog.close();
              }
            }

            switch (schedule.service) {
              case "HOME_VACCINATION": {
                const attendingClinics = clinics.filter(prop("attendsLocation"));
                if (attendingClinics.length === 0) {
                  if (window.confirm("Infelizmente não atendemos nesse endereço. Gostaria de marcar uma hora para comparecer na clínica?")) {
                    setSchedule(mergeLeft({ address, service: "CLINIC_VACCINATION" }));
                    autoSelectClinic(clinics);
                  }
                } else if (attendingClinics.length === 1) {
                  setSchedule(mergeLeft({ address, clinic: attendingClinics[0] }));
                  changeAddressDialog.close();
                } else {
                  setSchedule(mergeLeft({ address, clinic: attendingClinics[0] }));
                  clinicSelectDialog.open();
                  changeAddressDialog.close();
                }
                break;
              }

              case "CLINIC_VACCINATION": {
                autoSelectClinic(clinics);
                break;
              }

              default:
                break;
            }
          }}
        >
          <BottomButton type="submit">Salvar</BottomButton>
        </AddressSearch>
      </FullScreenDialog>

      <FullScreenDialog {...bindDialog(clinicSelectDialog)}>
        <TopBar left={<Box as={FaChevronLeft} onClick={changeDateDialog.close} />}>Escolha uma Unidade</TopBar>
        <ClinicSelect
          value={schedule.clinic}
          onChange={clinic => {
            setSchedule(mergeLeft({ clinic }));
            changeDateDialog.open();
            clinicSelectDialog.close();
          }}
        >
          <BottomButton type="submit">Próximo</BottomButton>
        </ClinicSelect>
      </FullScreenDialog>

      <FullScreenDialog {...bindDialog(changeDateDialog)}>
        <TopBar left={<Box as={FaChevronLeft} onClick={changeDateDialog.close} />}>Escolha um Horário</TopBar>
        <DateTimeSelector
          date={schedule.date}
          time={schedule.time}
          onChange={({ date, time }) => {
            setSchedule(mergeLeft({ date, time }));
            changeDateDialog.close();
          }}
        >
          <BottomButton type="submit">Salvar</BottomButton>
        </DateTimeSelector>
      </FullScreenDialog>

      <FullScreenDialog {...bindDialog(editPatientDialog)}>
        <TopBar left={<Box as={FaChevronLeft} onClick={editPatientDialog.close} />}>Dados do Paciente</TopBar>
        <PatientForm
          initialValues={editPatient}
          onSubmit={patient => {
            setSchedule(over(lensProp("patients"), map(p => p.id === editPatient.id ? { ...p, ...patient } : p)));
            setEditPatient(null);
            editPatientDialog.close();
          }}
        >
          <BottomButton type="submit">Salvar</BottomButton>
        </PatientForm>
      </FullScreenDialog>
    </>
  );
}

const ScheduleDetails = ({ id, children }) => {
  const [pollInterval, setPollInterval] = useState(0);

  const { data: { appointment = {} } = {}, loading } = useQuery(appointmentQuery, {
    variables: { id },
    skip: id == null,
    pollInterval,
  });

  React.useEffect(() => {
    const tenMinutes = 60 * 10 * 1000;
    const fiveSeconds = 5 * 1000;

    switch (appointment.status) {
      case "PENDING_APPROVAL":
        if (pollInterval !== tenMinutes) setPollInterval(tenMinutes);
        break;

      case "APPROVED":
        switch (appointment.payment?.type) {
          case "UnconfirmedPayment":
            if (pollInterval !== fiveSeconds) setPollInterval(5 * 1000);
            break;

          case "RefusedPayment":
            if (pollInterval !== tenMinutes) setPollInterval(appointment.payment?.canPay ? 0 : tenMinutes);
            break;

          default:
            if (pollInterval > 0) setPollInterval(0);
            break;
        }
        break;

      default:
        if (pollInterval > 0) setPollInterval(0);
        break;
    }
  }, [appointment, pollInterval]);

  const service = appointment |> path(["services", 0, "service"]);
  const { address, date, time, fees, total, services = [], clinic: { address: clinicAddress } = {} } = appointment;

  const patientsWithServices = services
    |> groupBy(path(["patient", "id"]))
    |> values
    |> map(services => [services[0].patient, services]);

  const chainHandle = useChainHandle();
  const { orderPaymentPath } = usePaths();

  if (loading) return null;

  const pendingPayment = appointment.payment?.type === "PendingPayment" || appointment.payment?.type === "PendingPixPayment";

  return (
    <>
      <Box p={4}>
        <Stack>
          {appointment.status === "PENDING_APPROVAL" && (
            <>
              <Text fontSize="xl" color="blue.600" fontWeight="bold">Recebemos seu agendamento!</Text>
              <Text>Seu agendamento foi recebido mas <b>ainda não está confirmado</b>.</Text>
              <Text>Você receberá um contato da clínica nas próximas horas com a confirmação.</Text>
            </>
          )}

          {appointment.status === "APPROVED" && (
            <>
              <Text fontSize="xl" color="blue.600" fontWeight="bold">Agendamento aprovado!</Text>

              {pendingPayment && appointment.payment?.canPay && (
                <>
                  <Text>Realize o pagamento <Link as={RouterLink} color="blue.600" to={orderPaymentPath(appointment.id)}>clicando aqui</Link>.</Text>
                </>
              )}

            </>
          )}

          {appointment.status === "CANCELLED" && (
            <>
              <Text fontSize="xl" color="blue.600" fontWeight="bold">Agendamento cancelado!</Text>
            </>
          )}

          {appointment.status === "EXPIRED" && (
            <>
              <Text fontSize="xl" color="blue.600" fontWeight="bold">Agendamento vencido!</Text>
            </>
          )}

          {appointment.status === "APPROVED" && appointment.payment?.type === "UnconfirmedPayment" && (
            <>
              <Alert status="warning">
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>Pagamento recebido!</AlertTitle>
                  <AlertDescription display="block">
                    Seu pagamento está em análise, você será notificado assim que estiver tudo certo!
                  </AlertDescription>
                </Box>
              </Alert>
            </>
          )}

          {appointment.status === "APPROVED" && appointment.payment?.type === "SuccessfullPayment" && (
            <>
              <Alert status="success">
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>Pagamento confirmado!</AlertTitle>
                </Box>
              </Alert>
            </>
          )}

          {appointment.status === "APPROVED" && appointment.payment?.type === "RefusedPayment" && (
            <>
              <Alert status="error">
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>Pagamento rejeitado</AlertTitle>
                  <AlertDescription display="block">
                    {!appointment.payment.canPay && (
                      <>
                        Seu pagamento foi rejeitado pela operadora, por favor entre em contato com a clínica para negociar outras formas de pagamento.
                      </>
                    )}

                    {appointment.payment.canPay && (
                      <>
                        Seu pagamento foi rejeitado pela operadora, por favor revise os dados de seu cartão e tente novamente <Link as={RouterLink} color="red.600" to={`/${chainHandle}/pedidos/${appointment.id}/pagamento`}>clicando aqui</Link>.
                      </>
                    )}
                  </AlertDescription>
                </Box>
              </Alert>
            </>
          )}

          {appointment.payment?.type === "RefundedPayment" && (
            <>
              <Alert status="warning">
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>Pagamento estornado</AlertTitle>
                  <AlertDescription display="block">
                    Seu pagamento foi estornado no dia {dayjs(appointment.payment.date).format("DD/MM/YY")}
                  </AlertDescription>
                </Box>
              </Alert>
            </>
          )}

          {appointment.payment?.type === "ChargedBackPayment" && (
            <>
              <Alert status="warning">
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>Pagamento cancelado</AlertTitle>
                  <AlertDescription display="block">
                    Seu pagamento foi cancelado no dia {dayjs(appointment.payment.date).format("DD/MM/YY")}
                  </AlertDescription>
                </Box>
              </Alert>
            </>
          )}

          {!["CANCELLED", "EXPIRED"].includes(appointment.status) && service === "HOME_VACCINATION" && (
            <>
              <Divider />

              <Stack>
                <Flex justifyContent="space-between">
                  <Text fontWeight="medium">Você será atendido em:</Text>
                </Flex>

                <Stack direction="row" spacing="0">
                  <Box width="80px"><img src={mapIcon} alt="Endereço" /></Box>
                  <Text as="address" flex="1" fontStyle="normal">
                    {joinNonNull([address.street, address.number], ", ")}<br />
                    {joinNonNull([address.city, address.state], " - ")}<br />
                    {address.zipCode}
                  </Text>
                </Stack>
              </Stack>
            </>
          )}

          {!["CANCELLED", "EXPIRED"].includes(appointment.status) && service === "CLINIC_VACCINATION" && (
            <>
              <Divider />

              <Stack>
                <Text fontWeight="medium">Compareça no endereço da clínica:</Text>

                <Stack direction="row" spacing="0">
                  <Box width="80px"><img src={mapIcon} alt="Endereço" /></Box>
                  <Text as="address" flex="1" fontStyle="normal">
                    {joinNonNull([clinicAddress.street, clinicAddress.number], ", ")} {clinicAddress.complement}<br />
                    {joinNonNull([clinicAddress.city, clinicAddress.state], " - ")}<br />
                    {formatZipCode(clinicAddress.zipCode)}
                  </Text>
                </Stack>
              </Stack>
            </>
          )}

          <Divider />

          <Stack>
            <Text fontWeight="medium">Horário:</Text>
            <Flex>
              <Icon as={CalendarIcon} mr="8px" mt="4px" boxSize="20px" color="gray.600" />
              <Box>
                <Text>{dayjs(date).format("dddd, DD [de] MMMM")}</Text>
                <Text>{formatScheduleTime(time)}</Text>
              </Box>
            </Flex>
          </Stack>
        </Stack>
      </Box>

      <Divider borderWidth="4px" borderLeftWidth="0" borderRightWidth="0" />

      <Box p={4}>
        <Stack>
          {patientsWithServices.map(([patient, services]) => (
            <React.Fragment key={patient.id}>
              <Stack>
                <Flex justifyContent="space-between" alignItems="flex-start">
                  <Box>
                    <Text fontWeight="medium">{patient.name}</Text>
                    <Text color="gray.400" fontSize="sm">{dayjs(patient.birthDate).format("DD/MM/YYYY")}</Text>
                  </Box>
                </Flex>

                <List>
                  {services.map(({ product, price }, key) => (
                    <ListItem fontSize="sm" key={key}>
                      <Flex justifyContent="space-between">
                        <Text>{product.name}</Text>
                        <Text whiteSpace="nowrap">R$ {formatNumber(price)}</Text>
                      </Flex>
                    </ListItem>
                  ))}
                </List>
              </Stack>
              <Divider />
            </React.Fragment>
          ))}

          {fees > 0 && (
            <Flex justifyContent="space-between">
              <Text>Taxa</Text>
              <Text whiteSpace="nowrap">R$ {formatNumber(fees)}</Text>
            </Flex>
          )}

          <Flex justifyContent="space-between">
            <Text fontSize="lg" fontWeight="bold">Total</Text>
            <Text fontSize="lg" fontWeight="bold" whiteSpace="nowrap">R$ {formatNumber(total)}</Text>
          </Flex>
        </Stack>
      </Box>

      {children({ appointment })}
    </>
  );
}

const creditCardMask = [/\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/];
const CreditCardInput = React.forwardRef((props, ref) => (
  <MaskedInput
    mask={creditCardMask}
    ref={ref}
    guide={false}
    showMask={false}
    {...props}
    render={(ref, props) => (
      <Input ref={ref} {...props} />
    )}
  />
));
CreditCardInput.displayName = "CreditCardInput";

const creditCardExpiryDateMask = [/\d/, /\d/, "/", /\d/, /\d/];
const CreditCardExpiryDateInput = React.forwardRef((props, ref) => (
  <MaskedInput
    mask={creditCardExpiryDateMask}
    ref={ref}
    guide={false}
    showMask={false}
    {...props}
    render={(ref, props) => (
      <Input ref={ref} {...props} />
    )}
  />
));
CreditCardExpiryDateInput.displayName = "CreditCardExpiryDateInput";

const creditCardCVVMask = [/\d/, /\d/, /\d/, /\d/];
const CreditCardCVVInput = React.forwardRef((props, ref) => (
  <MaskedInput
    mask={creditCardCVVMask}
    ref={ref}
    guide={false}
    showMask={false}
    {...props}
    render={(ref, props) => (
      <Input ref={ref} {...props} />
    )}
  />
));
CreditCardCVVInput.displayName = "CreditCardCVVInput";

const payAppointmentMutation = gql`
  mutation PayAppointment($input: PayAppointmentInput!) {
    result: payAppointment(input: $input) {
      __typename
      type: __typename
      ... on AppointmentPaymentError {
        message
      }
      ...AppointmentFragment
    }
  }
  ${appointmentFragment}
`;

const generateAppointmentPixPaymentMutation = gql`
  mutation GenerateAppointmentPixPayment($input: GenerateAppointmentPixPaymentInput!) {
    result: generateAppointmentPixPayment(input: $input) {
      __typename
      type: __typename
      ... on PixQRCodeGenerationError {
        message
      }
      ... on PixPaymentInformation {
        qrCode
      }
    }
  }
`;

const paymentFormSchema = yup.object().shape({
  installments: yup.number()
    .integer()
    .required("Informe a quantidade de parcelas"),
  cardHolder: yup.string()
    .required("Informe o nome e o sobrenome")
    .test("full-name", "Informe nome e o sobrenome", value => value.trim().includes(" ")),

  cardNumber: yup.string()
    .required("Informe o número do cartão")
    .test("number-length", "Número do cartão inválido", value => value?.replace(/\D/g, "")?.length === 16),

  cardExpiration: yup.string()
    .required("Informe a data de vencimento")
    .test("validate-expiration", "Data de vencimento inválida", value => {
      const parsedDate = dayjs(value, "MM/YY", true);
      return parsedDate.isValid() && parsedDate.isSameOrAfter(new Date(), "month");
    }),

  cardCVV: yup.string()
    .required("Informe o código de segurança")
    .test("cvv-length", "Código de segurança inválido", value => [3,4].includes(value?.replace(/\D/g, "")?.length)),
});


const queryString = params =>
  params
  |> toPairs
  |> map(([param, value]) => `${param}=${encodeURIComponent(value)}`)
  |> join("&")
  |> concat("?");

export const QRCodeURL = ({ url, size = 200, ...props }) => {
  const query = queryString({
    chs: `${size}x${size}`,
    cht: "qr",
    chl: url,
    choe: "UTF-8",
    chld: "M|4",
  });

  const imgURL = `https://chart.googleapis.com/chart${query}`;
  return <img src={imgURL} alt={url} {...props} />;
};

const PaymentInfo = ({ id }) => {
  const { data: { appointment = {} } = {}, loading } = useQuery(appointmentQuery, {
    variables: { id },
    skip: id == null,
  });

  const { fees, total, services = [] } = appointment;

  const servicesByProducts = services
    |> groupBy(path(["product", "id"]))
    |> values
    |> map(services => [services[0].product, services]);

  if (loading) return null;

  return (
    <>
      <List>
        {servicesByProducts.map(([product, services]) => (
          <ListItem key={product.id}>
            <Flex justifyContent="space-between">
              <Text>{services.length}x {product.name}</Text>
              <Text whiteSpace="nowrap">R$ {formatNumber(services.map(prop("price")).reduce((total, price) => big`${total} + ${price}`, 0))}</Text>
            </Flex>
          </ListItem>
        ))}

        {fees > 0 && (
          <ListItem>
            <Flex justifyContent="space-between">
              <Text>Taxa</Text>
              <Text whiteSpace="nowrap">R$ {formatNumber(fees)}</Text>
            </Flex>
          </ListItem>
        )}

        <ListItem>
          <Flex fontSize="lg" fontWeight="bold" justifyContent="space-between">
            <Text>Total</Text>
            <Text whiteSpace="nowrap">R$ {formatNumber(total)}</Text>
          </Flex>
        </ListItem>
      </List>

      <Divider />
    </>
  );
}

const Payment = ({ id, children }) => {
  const history = useHistory();
  const { orderCreditCardPaymentPath, orderPaymentPath, orderPixPaymentPath } = usePaths();

  const { data: { appointment = {} } = {}, loading } = useQuery(appointmentQuery, {
    variables: { id },
    skip: id == null,
  });

  if (loading) return null;

  if (!appointment.payment?.canPay) {
    return <Redirect to={orderPaymentPath(appointment.id)} />;
  }

  return (
    <>
      <Box p={4}>
        <Stack>
          <PaymentInfo id={id}/>

          <Stack>
            <Text>Selecione a forma de pagamento:</Text>
            <Button onClick={() => history.push(orderCreditCardPaymentPath(id))}>Cartão de crédito</Button>
            <Button onClick={() => history.push(orderPixPaymentPath(id))}>Pix</Button>
          </Stack>

          {loading || children({ loading })}
        </Stack>
      </Box>
    </>
  );
}

const CreditCardPayment = ({ children, id }) => {
  const history = useHistory();
  const { orderPath } = usePaths();

  const { data: { appointment = {} } = {}, loading } = useQuery(appointmentQuery, {
    variables: { id },
    skip: id == null,
  });

  const [payAppointment, { loading: saving }] = useMutation(payAppointmentMutation);

  const { payment = {} } = appointment;

  const { control, handleSubmit, errors, register, setError } = useForm({
    defaultValues: {
      cardNumber: "",
      cardHolder: "",
      cardCVV: "",
      cardExpiration: "",
      installments: "1",
    },
    resolver: yupResolver(paymentFormSchema),
  });

  const { encryptCreditCard } = usePagarMe();

  const onSubmit = async values => {
    const encryptedCreditCard = await encryptCreditCard({
      card_number: values.cardNumber,
      card_holder_name: values.cardHolder,
      card_expiration_date: values.cardExpiration,
      card_cvv: values.cardCVV,
    });

    const input = {
      appointmentId: appointment.id,
      encryptedCreditCard,
      installments: parseInt(values.installments, 10),
    };

    return payAppointment({ variables: { input } }).then(res => {
      const { type, message, id } = res.data.result;

      switch (type) {
        case "AppointmentPaymentError": {
          setError("payment", { message });
          break;
        }
        default: {
          history.replace(orderPath(id))
          break;
        }
      }
    });
  };

  if (loading) return null;

  if (!appointment.payment?.canPay) {
    return <Redirect to={orderPath(appointment.id)} />;
  }

  return (
    <>
      <Box p={4}>
        <Stack>
          <PaymentInfo id={id}/>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              {errors.payment && (
                <Alert status="error">
                  <AlertIcon />
                  <Box flex="1">
                    <AlertTitle>Pagamento rejeitado</AlertTitle>
                    <AlertDescription display="block">{errors.payment?.message}</AlertDescription>
                  </Box>
                </Alert>
              )}

              {payment?.canPay && payment?.installments?.length > 1 && (
                <Select placeholder="Selecione as parcelas" name="installments" ref={register()}>
                  {payment.installments.map(({ count, amount, interestRate }) => (
                    <option key={count} value={count}>
                      {count} x {formatNumber(amount)}
                      &nbsp;
                      {interestRate === 0 && "(sem juros)"}
                      {interestRate > 0 && `(juros ${formatNumber(interestRate * 100)}%)`}
                    </option>
                  ))}
                </Select>
              )}

              <FormControl isInvalid={errors.cardHolder}>
                <FormLabel>Nome e Sobrenome do Cartão</FormLabel>
                <Input name="cardHolder" ref={register()} />
                {errors.cardHolder && <FormErrorMessage>{errors.cardHolder?.message}</FormErrorMessage>}
              </FormControl>

              <Controller
                control={control}
                name="cardNumber"
                render={({ onChange, onBlur, value }) => (
                  <FormControl isInvalid={errors.cardNumber}>
                    <FormLabel>Número do Cartão</FormLabel>
                    <CreditCardInput onChange={onChange} onBlur={onBlur} value={value} />
                    {errors.cardNumber && <FormErrorMessage>{errors.cardNumber?.message}</FormErrorMessage>}
                  </FormControl>
                )}
              />

              <Stack direction="row" spacing={4}>
                <Controller
                  control={control}
                  name="cardExpiration"
                  render={({ onChange, onBlur, value }) => (
                    <FormControl isInvalid={errors.cardExpiration}>
                      <FormLabel>Data de Vencimento</FormLabel>
                      <CreditCardExpiryDateInput onChange={onChange} onBlur={onBlur} value={value} placeholder="MM/AA" />
                      {errors.cardExpiration && <FormErrorMessage>{errors.cardExpiration?.message}</FormErrorMessage>}
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="cardCVV"
                  render={({ onChange, onBlur, value }) => (
                    <FormControl isInvalid={errors.cardCVV}>
                      <FormLabel>Código de Segurança</FormLabel>
                      <CreditCardCVVInput onChange={onChange} onBlur={onBlur} value={value} />
                      {errors.cardCVV && <FormErrorMessage>{errors.cardCVV?.message}</FormErrorMessage>}
                    </FormControl>
                  )}
                />
              </Stack>

              {children}
            </Stack>

            {loading || children({ loading: saving })}
          </form>
        </Stack>
      </Box>
    </>
  );
}

const PixPayment = ({ id, children }) => {
  const { orderPath } = usePaths();

  const [pixQrCode, setPixQrCode] = useState("");
  const [pixError, setPixError] = useState("");

  const { data: { appointment = {} } = {}, loading: loadingAppointment } = useQuery(appointmentQuery, {
    variables: { id },
    skip: id == null,
  });

  const [generatePix, { loading: loadingPixPayment }] = useMutation(generateAppointmentPixPaymentMutation);

  const loading = useMemo(() => loadingAppointment || loadingPixPayment, [loadingAppointment, loadingPixPayment]);

  useEffect(() => {
    const input = { appointmentId: id };
    generatePix({ variables: { input } }).then(({ data }) => {
      const { type, message, qrCode } = data.result;

      switch (type) {
        case "PixQRCodeGenerationError": {
          setPixError(message);
          break;
        }
        default: {
          setPixQrCode(qrCode);
        }
      }
    });
  }, [generatePix, id]);

  if (loading) return null;

  if (!appointment.payment?.canPay) {
    return <Redirect to={orderPath(appointment.id)} />;
  }

  return (
    <>
      <Box p={4}>
        <Stack>
          <PaymentInfo id={id}/>

          <Stack>
            {pixError ? (
              <Alert status="error">
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>Pagamento por PIX indisponível</AlertTitle>
                  <AlertDescription display="block">{pixError}</AlertDescription>
                </Box>
              </Alert>
            ) : pixQrCode && (
              <>
                <Text style={{ textAlign: "center" }}>Escaneie o código QR:</Text>

                <Box style={{ display: "flex", justifyContent: "center" }}>
                  {pixQrCode && <QRCodeURL url={pixQrCode} style={{ width: "200px" }}/>}
                </Box>

                <Divider style={{ marginBottom: "30px" }}/>

                {pixQrCode && (
                  <Button onClick={() => copyToClipboard(pixQrCode)} size="sm">
                    Copiar
                  </Button>
                )}
              </>
            )}
          </Stack>

          {loading || children({ loading })}
        </Stack>
      </Box>
    </>
  );
}

const inVisibleScrollArea = (parent, item) => {
  const parentLeft = parent.scrollLeft;
  const parentRight = parentLeft + parent.clientWidth;

  const itemLeft = item.offsetLeft;
  const itemRight = item.clientWidth + itemLeft;

  return itemRight <= parentRight && itemLeft >= parentLeft;
};

const DateSelector = ({ label, dates, value, onChange, ...props }) => {
  const containerRef = useRef();

  const onForward = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      const items = [...container.querySelectorAll("[data-list-item]")];
      const visibleItems = dropWhile(i => !inVisibleScrollArea(container, i), items);
      const target = visibleItems.find(i => !inVisibleScrollArea(container, i));
      if (target) {
        container.scrollTo({ left: target.offsetLeft, behavior: "smooth" });
      }
    }
  }, [containerRef]);

  const onBackward = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      const items = [...container.querySelectorAll("[data-list-item]")];
      const firstVisible = items.findIndex(i => inVisibleScrollArea(container, i));
      if (firstVisible && firstVisible - 1 >= 0) {
        const target = items[firstVisible];
        const left = Math.max(0, target.offsetLeft + target.clientWidth - container.clientWidth);
        container.scrollTo({ left, behavior: "smooth" });
      }
    }
  }, [containerRef]);

  React.useEffect(() => {
    const container = containerRef.current;
    if (container && value) {
      const target = container.querySelector(`[data-list-value='${dayjs(value).format("YYYY-MM-DD")}']`);
      if (target && !inVisibleScrollArea(container, target)) {
        const left = Math.max(0, target.offsetLeft - container.clientWidth / 2);
        container.scrollLeft = left;
      }
    }
  }, [value, containerRef]);

  return (
    <Stack {...props}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box ml={3} flexShrink="0" as={FaChevronLeft} w="16x" h="16px" color="gray.500" mx="0" onClick={onBackward} cursor="pointer" />
        <Box>{label}</Box>
        <Box ml={3} flexShrink="0" as={FaChevronRight} w="16px" h="16px" color="gray.500" mx="0" onClick={onForward} cursor="pointer" />
      </Stack>

      <Stack ref={containerRef} direction="row" overflow="auto" style={{ scrollSnapType: "x mandatory", scrollSnapPointsX: "repeat(56px)" }} spacing="6px" position="relative" id="lol">
        {dates.map((date, i) => {
          const selected = value && dayjs(date).isSame(dayjs(value), "day");
          return (
            <Stack
              alignItems="center"
              spacing="2px"
              data-list-item="1"
              data-list-value={dayjs(date).format("YYYY-MM-DD")}
              key={i}
              rounded="6px"
              borderWidth="1px"
              borderColor="gray.300"
              cursor="pointer"
              {...selected && {
                bg: "blue.500",
                color: "white",
              }}
              px="4px"
              py="8px"
              style={{ scrollSnapAlign: "start", minWidth: "50px" }}
              onClick={() => onChange(date)}
            >
              <Text fontSize="xs" color={selected ? "white" : "gray.500"}>{dayjs(date).format("MMM")}</Text>
              <Text fontSize="24px">{dayjs(date).format("DD")}</Text>
              <Text fontSize="sm" color={selected ? "white" : "gray.500"}>{dayjs(date).format("ddd")}</Text>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}

const phoneNumberMask = value => {
  if (value.replace(/\D/g, "").length > 10) {
    return ["(", /[1-9]/, /[1-9]/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
  } else {
    return ["(", /[1-9]/, /[1-9]/, ")", " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
  }
};
const PhoneNumberInput = React.forwardRef((props, ref) => (
  <MaskedInput
    mask={phoneNumberMask}
    ref={ref}
    showMask={false}
    {...props}
    render={(ref, props) => (
      <Input ref={ref} {...props} />
    )}
  />
));
PhoneNumberInput.displayName = "PhoneNumberInput";

const cpfMask = [/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/];
const CPFInput = React.forwardRef((props, ref) => (
  <MaskedInput
    mask={cpfMask}
    ref={ref}
    showMask={false}
    {...props}
    render={(ref, props) => (
      <Input ref={ref} {...props} />
    )}
  />
));
CPFInput.displayName = "CPFInput";

const contactFormSchema = yup.object().shape({
  name: yup.string()
    .required("Informe o nome")
    .test("full-name", "O nome precisa ser completo", value => value.trim().includes(" ")),

  email: yup.string()
    .required("Informe o email")
    .email("Email inválido"),

  phoneNumber: yup.string()
    .transform(value => (value || "").replace(/\D/g, ""))
    .required("Informe o celular")
    .test(
      "validate-phone-number",
      "O número precisa ser de um celular",
      value => value === "" || value.length === 11
    ),

  cpf: yup.string()
    .required("Informe o CPF")
    .test("validate-cpf", "CPF inválido", value => value === "" || cpf.isValid(value)),
});

const ContactForm = ({ initialValues, onSubmit, children }) => {
  const { control, handleSubmit, errors, register } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phoneNumber: "",
      cpf: "",
      ...initialValues,
    },
    resolver: yupResolver(contactFormSchema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4} p={6}>
        <Text color="gray.600">Precisamos dos seus dados de contato para confirmar seu agendamento.</Text>

        <Divider />

        <FormControl isInvalid={errors.name}>
          <FormLabel>Nome</FormLabel>
          <Input name="name" ref={register()} />
          {errors.name && <FormErrorMessage>{errors.name?.message}</FormErrorMessage>}
        </FormControl>

        <FormControl isInvalid={errors.email}>
          <FormLabel>Email</FormLabel>
          <Input name="email" type="email" ref={register()} />
          {errors.email && <FormErrorMessage>{errors.email?.message}</FormErrorMessage>}
        </FormControl>

        <FormControl isInvalid={errors.phoneNumber}>
          <FormLabel>Celular</FormLabel>
          <Controller
            control={control}
            name="phoneNumber"
            render={({ onBlur, onChange, value }) => (
              <PhoneNumberInput name="phoneNumber" onChange={onChange} onBlur={onBlur} value={value} />
            )}
          />
          <FormHelperText>Informe o número completo com o DDD</FormHelperText>
          {errors.phoneNumber && <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>}
        </FormControl>

        <FormControl isInvalid={errors.cpf}>
          <FormLabel>CPF</FormLabel>
          <Controller
            control={control}
            name="cpf"
            render={({ onBlur, onChange, value }) => (
              <CPFInput name="cpf" onChange={onChange} onBlur={onBlur} value={value} />
            )}
          />
          {errors.cpf && <FormErrorMessage>{errors.cpf?.message}</FormErrorMessage>}
        </FormControl>

        {children}
      </Stack>
    </form>
  );
}

const colors = {
  blue: {
    50: "#e1f2ff",
    100: "#b5d4fe",
    200: "#87b7f8",
    300: "#599bf2",
    400: "#2c7eed",
    500: "#1265d3",
    600: "#084ea5",
    700: "#023877",
    800: "#00224a",
    900: "#000c1e",
  },

  cyan: {
    50: "#d6ffff",
    100: "#aaf7ff",
    200: "#7af2ff",
    300: "#47ebff",
    400: "#00cce6",
    500: "#00cce6",
    600: "#009eb4",
    700: "#007281",
    800: "#00454f",
    900: "#00191e",
  },

  green: {
    50: "#e0ffe5",
    100: "#b5fac0",
    200: "#87f599",
    300: "#59f172",
    400: "#2ced4b",
    500: "#12d332",
    600: "#07a426",
    700: "#01751a",
    800: "#00470d",
    900: "#001900",
  },
};

const theme = extendTheme({ colors });

export default function ScheduleFormFlow() {
  return (
    <ChakraProvider resetCSS theme={theme}>
      <BaseRoute>
        <FormWithContext />
      </BaseRoute>
    </ChakraProvider>
  );
}

const FormWithContext = () => {
  const scheduleContext = useScheduleForm();

  return (
    <ScheduleFormContext.Provider value={scheduleContext}>
      <FormForm />
    </ScheduleFormContext.Provider>
  )
}

const scheduleAppointmentMutation = gql`
  mutation ScheduleAppointment($input: ScheduleAppointmentInput!) {
    result: scheduleAppointment(input: $input) {
      __typename
      type: __typename
      ... on AppointmentError {
        message
      }
      ... on ScheduleUnavailableError {
        message
      }
      ... on UnavailableProductsError {
        message
      }
      ...AppointmentFragment
    }
  }
  ${appointmentFragment}
`;

const FormForm = () => {
  const history = useHistory();
  const chainHandle = useChainHandle();
  const {
    cartAddressPath,
    cartClinicSelectionPath,
    cartConfirmationPath,
    cartContactsPath,
    cartPatientsPath,
    cartSchedulePath,
    cartVaccineSelectionPath,
    orderCreditCardPaymentPath,
    orderPath,
    orderPaymentPath,
    orderPixPaymentPath,
    rootPath,
  } = usePaths();
  const { schedule, setSchedule, total, totalQuantity, resetCart, patientsWithProducts } = useCurrentScheduleForm();
  const graphql = useApolloClient();

  const allProductsValid =
    toPairs(schedule.products || {})
      .every(([id, { quantity = "" }]) => pathOr([], ["productsPatients", id], schedule).filter(Boolean).length >= parseInt(quantity, 10));

  const [scheduleAppointment, { loading: saving }] = useMutation(scheduleAppointmentMutation);

  const { data: { chain: chainData } = {} } = useQuery(chainQuery, {
    variables: {
      handle: chainHandle,
    },
    onCompleted: (data) => {
      if (data?.chain?.name) {
        document.title = `${data?.chain?.name} - Loja Online`;
      }
    },
  });

  const onSubmit = values => {
    setSchedule(mergeLeft(values));

    const onlyNumbers = replace(/\D/g, "");

    const input = {
      clinicId: schedule |> path(["clinic", "id"]),
      ...values |> pick(["name", "email"]),
      ...values |> pick(["cpf", "phoneNumber"]) |> map(onlyNumbers),
      ...schedule |> pick(["service", "date", "time"]),
      address: {
        ...schedule.address |> pick(["street", "number", "complement", "district", "city"]),
        ...schedule.address |> pick(["zipCode"]) |> filter(Boolean) |> map(onlyNumbers),
        ...schedule.address |> pick(["lat", "lng"]) |> (geoLocation => ({ geoLocation })),
        state: schedule.address.stateAbbreviation,
      },
      products: patientsWithProducts
        .flatMap(({ products, ...patient }) => products.map(product => ({
          productId: product.id,
          patient: {
            ...patient |> pick(["name", "sex", "birthDate", "motherName"]),
            ...patient |> pick(["cpf"]) |> map(onlyNumbers),
          },
        }))),
    };

    return scheduleAppointment({ variables: { input } }).then(res => {
      const { type, message, id } = res.data.result;

      switch (type) {
        case "AppointmentError":
        case "UnavailableProductsError":
        case "ScheduleUnavailableError":
          alert(message);
          history.replace(cartConfirmationPath());
          break;

        default: {
          history.replace(orderPath(id));
          resetCart();
        }
      }
    });
  }

  return (
    <>
      <Switch>
        <Route path={cartAddressPath[routeTemplate]}>
          <TopBar left={<Box as={FaChevronLeft} onClick={history.goBack} />}>Informe o Endereço</TopBar>
          <AddressSearch
            value={schedule.address}
            onChange={async address => {
              setSchedule(mergeLeft({ address }));

              const { data } = await graphql.query({
                query: clinicsQuery,
                variables: {
                  chain: chainHandle,
                  service: schedule.service,
                  location: address |> pick(["lat", "lng"]),
                },
                fetchPolicy: "network-only",
              });

              const clinics = data |> pathOr([], ["chain", "clinics"]);

              const autoSelectClinic = clinics => {
                if (clinics.length === 0) {
                  alert("Desculpe o inconveniente, mas essa clínica não está atendendo no momento.");
                } else if (clinics.length === 1) {
                  setSchedule(mergeLeft({ clinic: clinics[0] }));
                  history.push(cartSchedulePath());
                } else {
                  history.push(cartClinicSelectionPath());
                }
              }

              switch (schedule.service) {
                case "HOME_VACCINATION": {
                  const attendingClinics = clinics.filter(prop("attendsLocation"));
                  if (attendingClinics.length === 0) {
                    if (window.confirm("Infelizmente não atendemos nesse endereço. Gostaria de marcar uma hora para comparecer na clínica?")) {
                      setSchedule(mergeLeft({ service: "CLINIC_VACCINATION" }));
                      autoSelectClinic(clinics);
                    }
                  } else if (attendingClinics.length === 1) {
                    setSchedule(mergeLeft({ clinic: attendingClinics[0] }));
                    history.push(cartSchedulePath());
                  } else {
                    history.push(cartClinicSelectionPath());
                  }
                  break;
                }

                case "CLINIC_VACCINATION": {
                  autoSelectClinic(clinics);
                  break;
                }

                default:
                  break;
              }
            }}
          >
            <BottomButton type="submit">Próximo</BottomButton>
          </AddressSearch>
        </Route>

        <Route path={cartClinicSelectionPath[routeTemplate]}>
          {schedule.address == null && <Redirect to={rootPath()} />}

          <TopBar left={<Box as={FaChevronLeft} onClick={history.goBack} />}>Selecione a Unidade</TopBar>
          <ClinicSelect
            value={schedule.clinic}
            onChange={clinic => {
              setSchedule(mergeLeft({ clinic }));
              history.push(cartSchedulePath());
            }}
          >
            <BottomButton type="submit">Próximo</BottomButton>
          </ClinicSelect>
        </Route>

        <Route path={cartPatientsPath[routeTemplate]}>
          {(!schedule.products || schedule.products.length === 0) && <Redirect to={rootPath()} />}

          <TopBar left={<Box as={FaChevronLeft} onClick={history.goBack} />}>Informe os Pacientes</TopBar>
          <PatientsList>
            {allProductsValid && (
              <BottomButton onClick={() => history.push(cartConfirmationPath())}>Próximo</BottomButton>
            )}
          </PatientsList>
        </Route>

        <Route path={cartVaccineSelectionPath[routeTemplate]}>
          {(!schedule.clinic || !schedule.service) && <Redirect to={rootPath()} />}

          <TopBar left={<Box as={FaChevronLeft} onClick={history.goBack} />}>Selecione as Vacinas</TopBar>
          <ProductList>
            {(schedule.products |> values |> map(prop("quantity")) |> sum) > 0 && (
              <BottomButton onClick={() => history.push(cartPatientsPath())}>
                <Flex justifyContent="space-between" width="100%">
                  <Box w="100px" position="relative">
                    <Box w="24px" h="24px" as={HiOutlineShoppingCart} />
                    <Box w="14px" h="14px" fontSize="10px" fontWeight="bold" position="absolute" top="-4px" left="16px" color="blue.600" bg="white" borderRadius="15px">
                      {totalQuantity}
                    </Box>
                  </Box>
                  <div>Próximo</div>
                  <Box w="100px" whiteSpace="nowrap">{total > 0 ? `R$ ${formatNumber(total)}` : null}</Box>
                </Flex>
              </BottomButton>
            )}
          </ProductList>
        </Route>

        <Route path={cartConfirmationPath[routeTemplate]}>
          {(!schedule.clinic || !schedule.service || !schedule.products || schedule.products.length === 0) && <Redirect to={rootPath()} />}

          <TopBar left={<Box as={FaChevronLeft} onClick={history.goBack} />}>Confirme seu Agendamento</TopBar>
          <Confirmation>
            <BottomButton onClick={() => history.push(cartContactsPath())}>Confirmar</BottomButton>
          </Confirmation>
        </Route>

        <Route path={cartSchedulePath[routeTemplate]}>
          {(!schedule.clinic || !schedule.service) && <Redirect to={rootPath()} />}

          <TopBar left={<Box as={FaChevronLeft} onClick={history.goBack} />}>Escolha o Horário</TopBar>
          <DateTimeSelector
            onChange={({ date, time }) => {
              setSchedule(mergeLeft({ date, time }));
              history.push(cartVaccineSelectionPath());
            }}
          >
            <BottomButton type="submit">Próximo</BottomButton>
          </DateTimeSelector>
        </Route>

        <Route path={cartContactsPath[routeTemplate]}>
          {(!schedule.clinic || !schedule.service || !schedule.products || schedule.products.length === 0) && <Redirect to={rootPath()} />}

          <TopBar left={<Box as={FaChevronLeft} onClick={history.goBack} />}>Dados de Contato</TopBar>
          <ContactForm
            initialValues={schedule |> pick(["name", "email", "cpf", "phoneNumber"])}
            onSubmit={onSubmit}
          >
            {!saving && <BottomButton type="submit" disabled={saving}>Finalizar</BottomButton>}
            {saving && (
              <BottomBar>
                <Box position="fixed" bottom="0" left="0" right="0">
                  <Progress height="50px" hasStripe isAnimated value={100}>Agendando</Progress>
                </Box>
              </BottomBar>
            )}
          </ContactForm>
        </Route>

        <Route path={orderPaymentPath[routeTemplate]} exact>
          {({ match }) => (
            <>
              <TopBar bg="white" color="gray.800" >Pagamento</TopBar>
              <Payment id={match |> path(["params", "id"])}>
                {({ loading }) => (
                  <>
                  {!loading &&
                    <BottomButton
                      disabled={saving}
                      onClick={() => history.push(orderPath(match |> path(["params", "id"])))}
                    >Retornar ao agendamento</BottomButton>
                  }
                  {loading && (
                    <BottomBar>
                      <Box position="fixed" bottom="0" left="0" right="0">
                        <Progress height="50px" hasStripe isAnimated value={100}>Processando</Progress>
                      </Box>
                    </BottomBar>
                  )}
                  </>
                )}
              </Payment>
            </>
          )}
        </Route>

        <Route path={orderCreditCardPaymentPath[routeTemplate]}>
          {({ match }) => (
            <>
              <TopBar bg="white" color="gray.800" >Pagamento</TopBar>
              <CreditCardPayment id={match |> path(["params", "id"])}>
                {({ loading }) => (
                  <>
                  {!loading && <BottomButton type="submit" disabled={saving}>Pagar</BottomButton>}
                  {loading && (
                    <BottomBar>
                      <Box position="fixed" bottom="0" left="0" right="0">
                        <Progress height="50px" hasStripe isAnimated value={100}>Processando</Progress>
                      </Box>
                    </BottomBar>
                  )}
                  </>
                )}
              </CreditCardPayment>
            </>
          )}
        </Route>

        <Route path={orderPixPaymentPath[routeTemplate]} exact>
          {({ match }) => (
            <>
              <TopBar bg="white" color="gray.800" >Pagamento</TopBar>
              <PixPayment id={match |> path(["params", "id"])}>
                {({ loading }) => (
                  <>
                  {!loading &&
                    <BottomButton
                      disabled={saving}
                      onClick={() => history.push(orderPath(match |> path(["params", "id"])))}
                    >Retornar ao agendamento</BottomButton>
                  }
                  {loading && (
                    <BottomBar>
                      <Box position="fixed" bottom="0" left="0" right="0">
                        <Progress height="50px" hasStripe isAnimated value={100}>Processando</Progress>
                      </Box>
                    </BottomBar>
                  )}
                  </>
                )}
              </PixPayment>
            </>
          )}
        </Route>

        <Route path={orderPath[routeTemplate]}>
          {({ match }) => (
            <>
              <TopBar
                bg="white"
                color="gray.800"
              >
                {chainData?.name}
              </TopBar>

              <ScheduleDetails id={match |> path(["params", "id"])}>
                {({ appointment }) => (
                  <>
                    {appointment.payment?.canPay && (
                      <BottomButton onClick={() => history.push(orderPaymentPath(appointment.id))}>
                        Pagar
                      </BottomButton>
                    )}

                    {!appointment.payment?.canPay && (
                      <BottomButton onClick={() => history.push(rootPath())}>
                        Fazer outro Agendamento
                      </BottomButton>
                    )}
                  </>
                )}
              </ScheduleDetails>
            </>
          )}
        </Route>

        <Route path={rootPath[routeTemplate]}>
          <TopBar
            bg="white"
            color="gray.800"
          >
            {chainData?.name}
          </TopBar>
          <Landing
            onChange={service => {
              setSchedule(mergeLeft({ service }));
              history.push(cartAddressPath());
            }}
          />
        </Route>

        <Route path="/:chain/*">
          <Redirect to={rootPath()} />
        </Route>

        <Route path="*">
          <Redirect to={rootPath()} />
        </Route>
      </Switch>
    </>
  )
}
