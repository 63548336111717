import { useCallback, useRef, useState } from "react";
import { identity } from "ramda";

export * from "./projections";

export const useGooglePlacesAutocomplete = ({
  onError = identity,
  onSearchBegin = identity,
  onPredictions = identity,
  onPlaceDetails = identity,
} = {}) => {
  const autocompleteRef = useRef();
  const sessionTokenRef = useRef();
  const placesServiceRef = useRef();
  const [predictions, setPredictions] = useState([]);
  const [searching, setSearching] = useState(false);
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const attributionRef = useRef();

  const search = useCallback(({ input, ...params }) => {
    if (!window.google || !window.google.maps) return;

    if (autocompleteRef.current == null) {
      autocompleteRef.current = new window.google.maps.places.AutocompleteService();
    }

    if (sessionTokenRef.current == null) {
      sessionTokenRef.current = new window.google.maps.places.AutocompleteSessionToken();
    }
    const sessionToken = sessionTokenRef.current;

    setSearching(true);
    onSearchBegin({ input });
    return autocompleteRef.current.getPlacePredictions(
      { input, sessionToken, ...params },
      (result, status) => {
        setSearching(false);
        switch (status) {
          case window.google.maps.places.PlacesServiceStatus.OK: {
            setPredictions(result);
            onPredictions({ input, predictions: result });
            break;
          }

          case window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
            setPredictions([]);
            onPredictions({ input, predictions: [] });
            break;

          default: {
            const message = `Google Places getPlacePredictions returned unexpected status: ${status}`;
            console.error(message);
            onError({ input, error: { status, message } });
          }
        }
      }
    );
  }, [autocompleteRef, sessionTokenRef, onError, onPredictions, onSearchBegin]);

  const getDetails = useCallback(placeDetailsRequest => (
    new Promise((resolve, reject) => {
      if (!window.google || !window.google.maps) return;

      if (placesServiceRef.current == null) {
         placesServiceRef.current = new window.google.maps.places.PlacesService(attributionRef.current);
      }
      const sessionToken = sessionTokenRef.current;

      setFetchingDetails(true);
      placesServiceRef.current.getDetails({ sessionToken, ...placeDetailsRequest }, (place, status) => {
        setFetchingDetails(false);
        sessionTokenRef.current = null;

        switch (status) {
          case window.google.maps.places.PlacesServiceStatus.OK: {
            onPlaceDetails(place);
            resolve(place);
            break;
          }

          default: {
            console.error("Google Places getDetails returned unexpected status:", status);
            onError({ error: status });
            reject(status);
          }
        }
      });
    })
  ), [onError, onPlaceDetails, attributionRef, placesServiceRef, sessionTokenRef]);

  return {
    search,
    searching,
    fetchingDetails,
    getDetails,
    predictions,
    registerAttributionRef: attributionRef,
    clearPredictions: () => setPredictions([]),
  };
}
