import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

export const createAPIClient = ({ url }) => {
  const client = new ApolloClient({
    uri: url,
    cache: new InMemoryCache({
      possibleTypes: {
        Payment: [
          "PendingPayment",
          "UnconfirmedPayment",
          "SuccessfullPayment",
          "RefusedPayment",
          "RefundedPayment",
          "ChargedBackPayment",
          "PendingPixPayment",
        ],
      },
    }),
  });

  return client;
}

export const ClickVacinasAPIProvider = ApolloProvider;
