import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const FullScreenDialog = props => (
  <Dialog fullScreen hideBackdrop disableScrollLock TransitionComponent={Transition} {...props} />
);

export const useDialogState = ({ initialOpen = false } = {}) => {
  const [opened, setOpen] = React.useState(initialOpen);
  const close = React.useCallback(() => setOpen(false), []);
  const open = React.useCallback(() => setOpen(true), []);
  return {
    opened,
    open,
    close,
  };
}

export const bindDialog = dialog => ({ open: dialog.opened, onClose: dialog.close });
