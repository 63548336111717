import "core-js/stable";
import "whatwg-fetch";
import "dom4";

import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter } from "react-router-dom";

import dayjs from "dayjs";
import dayjsCustomParseFormat from "dayjs/plugin/customParseFormat";
import dayjsIsSameOrAfter from "dayjs/plugin/isSameOrAfter";
import "dayjs/locale/pt-br";

import App from "./components/Scheduling/mobile";
import { PathProvider } from "./components/Path";
import { createAPIClient, ClickVacinasAPIProvider } from "./lib/clickvacinas-api";
import { PagarMeProvider } from "./lib/pagarme";

// TODO: remover quando o @chakra-ui resolver o bug
const media = window.matchMedia("min-width: 0px")?.__proto__;
if (media) {
  if (!media.addEventListener) media.addEventListener = function() {};
  if (!media.removeEventListener) media.removeEventListener = function() {};
}

dayjs.locale("pt-br");
dayjs.extend(dayjsCustomParseFormat);
dayjs.extend(dayjsIsSameOrAfter);

const clickVacinasAPI = createAPIClient({
  url: process.env.NODE_ENV === "development"
    ? "http://localhost:3000/clickvacinas/graphql"
    : (process.env.REACT_APP_API_URL || "/clickvacinas/graphql"),
});

const useChainOwnDomain = process.env.NODE_ENV === "development"
  ? !!JSON.parse(process.env.REACT_APP_CHAIN_OWN_DOMAIN)
  : !window.location.hostname.includes("clickvacinas.com.br");

ReactDOM.render(
  (
    <ClickVacinasAPIProvider client={clickVacinasAPI}>
      <PagarMeProvider encryptionKey={process.env.REACT_APP_PAGARME_ENCRYPTION_KEY}>
        <BrowserRouter>
          <PathProvider hostnameChain={useChainOwnDomain}>
            <App />
          </PathProvider>
        </BrowserRouter>
      </PagarMeProvider>
    </ClickVacinasAPIProvider>
  ),
  document.getElementById("root"),
);
