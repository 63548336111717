import { useCallback, useState } from "react";

const parseItem = key => {
  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch (err) {
    return null;
  }
}

export const useLocalStorage = (key, initial) => {
  const [value, setValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initial;
    } catch (err) {
      return initial;
    }
  });

  const set = useCallback(value => {
    try {
      const item = value instanceof Function ? value(parseItem(key)) : value;
      window.localStorage.setItem(key, JSON.stringify(item));
      setValue(item);
    } catch (err) {
      // Do nothing
    }
  }, [key]);

  return [value, set];
}
