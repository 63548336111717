import React, { createContext, useContext } from "react";

const PagarMeContext = createContext({ encryptionKey: "" });

export const PagarMeProvider = ({ encryptionKey, ...props }) =>
  <PagarMeContext.Provider {...props} value={{ encryptionKey }} />;

export const usePagarMe = ({ encryptionKey } = {}) => {
  const context = useContext(PagarMeContext);

  return {
    encryptCreditCard: card =>
      window.pagarme.client
        .connect({ encryption_key: encryptionKey || context.encryptionKey })
        .then(client => client.security.encrypt(card)),
  };
}
