import { anyPass, applySpec, compose, contains, find, map, path, prop, propSatisfies } from "ramda";

const coordinates = coords => ({ lat: coords.lat(), lng: coords.lng() });

const includesAny = items => anyPass(map(contains, items));

const addressComponent = types => compose(find(propSatisfies(includesAny(types), "types")), prop("address_components"));

const shortAddressComponent = types => compose(prop("short_name"), addressComponent(types));

const longAddressComponent = types => compose(prop("long_name"), addressComponent(types));

export const placeCountry = longAddressComponent(["country"]);

export const placeCountryAbbreviation = shortAddressComponent(["country"]);

export const placeState = longAddressComponent(["administrative_area_level_1"]);

export const placeStateAbbreviation = shortAddressComponent(["administrative_area_level_1"]);

export const placeCity = longAddressComponent(["administrative_area_level_2"]);

export const placeDistrict = longAddressComponent(["sublocality_level_1", "sublocality"]);

export const placeStreet = longAddressComponent(["street_name", "route"]);

export const placeStreetNumber = longAddressComponent(["street_number"]);

export const placeZipCode = longAddressComponent(["postal_code"]);

export const placeCoordinates = compose(coordinates, path(["geometry", "location"]));

export const placeAddress = applySpec({
  country: placeCountry,
  countryAbbreviation: placeCountryAbbreviation,
  state: placeState,
  stateAbbreviation: placeStateAbbreviation,
  city: placeCity,
  district: placeDistrict,
  street: placeStreet,
  number: placeStreetNumber,
  zipCode: placeZipCode,
});

export const markerCoordinates = compose(coordinates, prop("position"));

export const predictionPlaceId = prop("place_id");

export const predictionMainText = path(["structured_formatting", "main_text"]);

export const predictionSecondaryText = path(["structured_formatting", "secondary_text"]);
