import React, { useContext } from "react";
import { drop, fromPairs, map, zip } from "ramda";
import { useParams, Route } from "react-router-dom";

const PathContext = React.createContext({ hostnameChain: false });

export const PathProvider = ({ hostnameChain, ...props }) =>
  <PathContext.Provider {...props} value={{ hostnameChain }} />;

const usePathProvider = () => useContext(PathContext);

export const useChainHandle = () => {
  const { hostnameChain } = usePathProvider();
  const { chain } = useParams();
  return hostnameChain ? window.location.hostname : chain;
}

const useBasePath = () => {
  const chainHandle = useChainHandle();
  const { hostnameChain } = usePathProvider();
  return hostnameChain ? "/" : `/${chainHandle}`;
}

export const routeTemplate = Symbol("routeTemplate");

export const usePaths = () => {
  const { hostnameChain } = usePathProvider();
  const basePath = useBasePath();
  const buildPath = (...segments) =>
    [basePath, ...segments]
      .map(String)
      .filter(Boolean)
      .join("/")
      .replace(/\/\//g, "/");

  // Função que gera uma nova rota na aplicação. A ideia é que cada rota seja definida
  // usando a sintxe do React Router (ex: path("/some/path/:param1/other/:param2")).
  // Isso irá gerar uma nova função, que poderá ser chamada com dois argumentos
  // (um para cada parametro). Além disso, a função tem a propriedade `template`
  // que armazena o template original, para que possamos repassar para o React Router.
  // Ex:
  //
  // ```
  // const postPath = path("/posts/:id");
  // const postCommentPath = path("/posts/:id/comments/:commentId");
  //
  // console.assert(postPath("10") === "/posts/10");
  // console.assert(postCommentPath("10", "30") === "/posts/10/comments/30");
  //
  // ```
  //
  // O template da route pode ser acessada pelo símbolo exportado no módulo:
  // ```
  // const postPath = path("/posts/:id");
  // console.assert(postPath[routeTemplate] === "/posts/:id");
  // ```
  const path = template => {
    const requiredParams = template.split("/").filter(segment => segment.startsWith(":"));
    const builder = (...args) => {
      if (args.length < requiredParams.length) {
        throw new Error(`Missing path params: ${drop(args.length, requiredParams).join(", ")}`);
      }
      const params = requiredParams |> zip(args) |> map(([val, param]) => [param, val]) |> fromPairs;

      const segments = template
        .split("/")
        .map(segment => segment.startsWith(":") ? params[segment] : segment);

      return buildPath(...segments);
    }
    builder[routeTemplate] = hostnameChain ? template : `/:chain${template}`;
    return builder;
  }

  return {
    cartAddressPath: path("/endereco"),
    cartConfirmationPath: path("/confirmacao"),
    cartContactsPath: path("/contato"),
    cartClinicSelectionPath: path("/unidades"),
    cartPatientsPath: path("/pacientes"),
    cartSchedulePath: path("/horario"),
    cartVaccineSelectionPath: path("/vacinas"),
    orderPath: path("/pedidos/:id"),
    orderPaymentPath: path("/pedidos/:id/pagamento"),
    orderCreditCardPaymentPath: path("/pedidos/:id/pagamento/cartao-de-credito"),
    orderPixPaymentPath: path("/pedidos/:id/pagamento/pix"),
    rootPath: path("/"),
  };
}

export const BaseRoute = props => {
  const { hostnameChain } = usePathProvider();

  return (
    <Route {...props} path={hostnameChain ? "/" : "/:chain"} />
  );
}
